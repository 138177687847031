.ant-page-header {
  padding-left: 0 !important;
  padding-bottom: 30px !important;
  .ant-page-header-title-view-title {
    font-weight: bold;
    font-size: 42px;
    line-height: 120%;
  }
  .ant-page-header-title-view-extra {
    display: flex;
    > * {
      margin-left: 10px;
      margin-right: 10px;
      .media-custom-max(
        @screen-tablet,
        {margin-left: 0; margin-right: 0; margin-top: 10px;}
      );
    }

    .media-custom-max(
      @screen-tablet,
      {position: relative; flex-direction: column;}
    );
  }

  @media screen and (max-width: (@screen-desktop - 1px)) {
    padding-bottom: 30px !important;
  }
}

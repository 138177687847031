@import "flex-helpers";

.font {
  &-italic {
    font-style: italic;
  }
  &-regular {
    font-weight: normal;
  }
  &-bolder {
    font-weight: 500;
  }
  &-bold {
    font-weight: bold;
  }
}

.text {
  &-underlined {
    text-decoration: underline;
  }
  &-disabled {
    opacity: 0.3;
  }
  &-left {
    text-align: left !important;
  }
  &-rigth {
    text-align: right !important;
  }
  &-center {
    text-align: center !important;
  }

  &-common {
    font-size: 16px;
    line-height: 150%;
  }

  &-black {
    color: @text-color !important;
  }
  &-gray {
    color: @text-gray !important;
  }
  &-white {
    color: #fff !important;
  }

  &-label {
    //font-weight: 500;
    font-size: 12px;
    line-height: 150%;
  }

  &-normal {
    font-size: 14px;
    line-height: 17px;
  }
}

// generate fs-n classes
.font-sizes(@size) when (@size > 10) {
  .fs-@{size} {
    font-size: ~"@{size}px !important";
  }
  .font-sizes(@size - 1);
}

.font-sizes(54);

@margin-direction: top bottom left right;

.generate-margin-for-direction(@direction, @size) when (@size >= 10) {
  .m-@{direction}-@{size} {
    margin-@{direction}: ~"@{size}px !important";
  }
  // m-lg-?
  @media screen and (min-width: @screen-desktop) {
    .m-lg-@{direction}-@{size} {
      margin-@{direction}: ~"@{size}px !important";
    }
  }
  // m-md-?
  @media screen and (min-width: (@screen-phone + 1px)) and (max-width: (@screen-desktop - 1px)) {
    .m-md-@{direction}-@{size} {
      margin-@{direction}: ~"@{size}px !important";
    }
  }
  // m-xs-?
  @media screen and (max-width: @screen-phone) {
    .m-xs-@{direction}-@{size} {
      margin-@{direction}: ~"@{size}px !important";
    }
  }

  .generate-margin-for-direction(@direction, @size - 10);
}

.generate-margins-auto(@list, @i: 1) when (@i <= length(@list)) {
  @direction: extract(@list, @i);
  .m-@{direction}-auto {
    margin-@{direction}: auto;
  }
  // m-lg-?-auto
  @media screen and (min-width: @screen-desktop) {
    .m-lg-@{direction}-auto {
      margin-@{direction}: auto;
    }
  }
  @media screen and (min-width: (@screen-phone + 1px)) and (max-width: (@screen-desktop - 1px)) {
    .m-md-@{direction}-auto {
      margin-@{direction}: auto;
    }
  }
  @media screen and (max-width: @screen-phone) {
    .m-xs-@{direction}-auto {
      margin-@{direction}: auto;
    }
  }

  .generate-margins-auto(@list, @i + 1);
}

.generate-margins(@list, @i: 1) when (@i <= length(@list)) {
  // extract the right color from the list
  @direction: extract(@list, @i);
  // apply the background to the selector
  .generate-margin-for-direction(@direction, 200);

  // recursive call for the next color
  .generate-margins(@list, @i + 1);
}

.generate-margins(@margin-direction);
.generate-margins-auto(@margin-direction);

@media screen and (min-width: @screen-desktop) {
  .m-lg-auto {
    margin: auto;
  }
}
@media screen and (min-width: (@screen-phone + 1px)) and (max-width: (@screen-desktop - 1px)) {
  .m-md-auto {
    margin: auto;
  }
}
@media screen and (max-width: @screen-phone) {
  .m-xs-auto {
    margin: auto;
  }
}
// custom links

.link {
  transition: all 0.1s linear;

  &-common {
    color: @text-color;
    font-size: 12px;
    line-height: 14px;
  }

  &-primary {
    text-align: center;
    background: @btn-primary-bg;
    border-radius: 8px;
    color: white !important;
    min-height: 36px;
    line-height: 16px;
    padding: 10px 18.5px;
    &:hover {
      color: white;
      background: @btn-primary-bg-hover;
    }
    &:active,
    &:focus {
      color: white;
      background: @btn-primary-bg-active;
    }
  }
  &-big {
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    color: @text-color;
    text-decoration: underline;
    &:hover,
    &:active,
    &:focus {
      text-decoration: underline;
      color: @text-color;
    }
  }
  &-underlined {
    color: @text-color;
    font-size: 14px;
    line-height: 17px;
    text-decoration: underline;
    &:hover,
    &:active,
    &:focus {
      text-decoration: underline;
      color: @text-color;
    }
  }
}

.flex {
  display: flex !important;
  &-j-center {
    justify-content: center !important;
  }
  &-j-end {
    justify-content: flex-end !important;
  }
  &-j-space-between {
    justify-content: space-between !important;
  }
  &-align-center {
    align-items: center !important;
  }

  &-grow-1 {
    flex-grow: 1 !important;
  }
  @media screen and (min-width: @screen-desktop) {
    &-lg-grow-1 {
      flex-grow: 1 !important;
    }
  }
  @media screen and (min-width: (@screen-phone + 1px)) and (max-width: (@screen-desktop - 1px)) {
    &-md-grow-1 {
      flex-grow: 1 !important;
    }
  }
  @media screen and (max-width: @screen-phone) {
    &-xs-grow-1 {
      flex-grow: 1 !important;
    }
  }
}

.on-hover-cursor {
  &-pointer {
    &:hover {
      cursor: pointer;
    }
  }
}

.animated {
  transition: all 0.1s linear;
}

.section-title {
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 20px;
}

.w-xs-100p {
  .media-phone({width: 100%;});
}

.m-auto {
  margin: 0 auto !important;
}
.m-top-5 {
  margin-top: 5px !important;
}

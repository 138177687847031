@import "../../styles/responsive";
@import "../../styles/ant-theme";

ul.hr {
  margin-bottom: 30px;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  &.tagged-nav-bar-compact {
    margin-bottom: 0;
  }
}

ul.hr li {
  margin-right: 12px;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  padding: @status-padding-top-bottom @status-padding-left-right;

  &.tag-nav-bar {
    height: 26px;
    margin-bottom: 12px;
    position: relative;
    &-selected {
      font-weight: bold;
      background: #f1f0ff;
      border-radius: 6px;
    }
  }
  &.extra-content {
    padding: 0 0 0 10px;
  }
}

.tagged-nav-bar-compact & {
  .tag-nav-bar {
    margin-bottom: 0 !important;
  }
}

.pending-row {
  color: @pending-color;
  .ant-checkbox {
    padding-top: @checkbox-padding-top;
  }
}
.normal-row {
  .ant-checkbox {
    padding-top: @checkbox-padding-top;
  }
}

.tag-color {
  color: #3d3d46 !important;
  &-selected {
    color: @primary-color !important;
  }
}

th {
  font-size: 12px;
  font-weight: bold;
  line-height: 14px;
}

.row-selection {
  padding-top: 5px;
}

.ant-page-header {
  .ant-page-header-title-view-title {
    font-weight: 800;
  }
}

.dropdown {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  margin: 28px 0 20px 0;
}

.phone-number-column, .license-expiry-date-column {
  min-width: 125px;
}
.license-class-column {
  min-width: 125px;
}

.destination-arrow.status-col {
  &::before {
    width: 65%;
    left: 32%;
  }
}

.ant-table {
  .ant-table-tbody {
    & > tr {
      td.tracking-icon-col {
        padding: 0 !important;
        span.tracking-icon {
          margin: 0 10px 0 16px;
        }
        & + td {
          padding-left: 0 !important;
        }
      }
    }
  }

  .ant-table-thead {
    & > tr {
      th.tracking-icon-col {
        padding: 0 !important;
        padding-left: 16px !important;
        & + th {
          padding-left: 0 !important;
        }
      }
    }
  }
}

.tracking-modal {
  .ant-modal-content {
    min-height: 694px;
    .ant-modal-header {
      padding: 24px !important;
      padding-bottom: 19px !important;
      .ant-modal-title {
        font-size: 16px;
        line-height: 19px;
        text-align: left;
      }
    }
    .ant-modal-body {
      padding: 0 24px 24px !important;
    }
  }
  .close-icon {
    position: absolute;
    width: 24px;
    height: 24px;
    top: 24px;
    right: 24px;
  }
}

@primary-color: #837ae9;@text-color: #3d3d46;@text-gray: #a2a7b0;@heading-color: @text-color;@base-disabled-color: #f0f0f0;@base-disabled-text-color: #696b6d;@disabled-bg: @base-disabled-color;@gray: #cdd1d9;@font-size-lg: 16px;@border-color-base: @text-gray;@controls-border: @gray;@border-color-split: @controls-border;@card-radius: 6px;@card-shadow: 0px 10px 20px rgba(65, 39, 70, 0.1);@card-padding-base: 30px;@btn-height-base: 30px;@btn-padding-base: 0 10px;@btn-primary-bg: #ea5353;@btn-primary-bg-hover: #c74545;@btn-primary-bg-active: #983131;@btn-disable-border: @base-disabled-color;@btn-padding-lg: 0 32px;@btn-height-lg: 42px;@btn-default-color: @border-color-base;@input-border-color: @controls-border;@select-border-color: @controls-border;@input-height-lg: 42px;@input-disabled-bg: #fafafa;@page-header-padding-horizontal: 0;@zindex-picker: 1000001;@table-header-bg: #f6f7fb;@table-row-hover-bg: #f6f7fb;@badge-dot-size: 10px;@status-padding-top-bottom: 5px;@status-padding-left-right: 10px;@checkbox-padding-top: 8px;@popup-menu-top-bottom: 10px;@popup-menu-left-right: 21px;@pending-color: #a2a7b0;@radio-wrapper-border-color: #a8a2f0;@switch-checked-bg: #69E283;@switch-unchecked-bg: @gray;
.ant-badge {
  &-dot {
    top: 3px;
    box-shadow: none !important;
    right: 2px;
    z-index: auto;
    width: 6px;
    height: 6px;
    background: #f5222d;
    border-radius: 100%;
  }
}

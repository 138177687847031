.embedded-description {
  &-column {
    padding: 16px 0 16px 16px;
    vertical-align: top;
    [data-sc-index] & {
      padding: 16px 0 16px 16px;
    }
    &:nth-child(1) {
      padding-left: 0;
    }
  }
  &-label {
    font-size: 11px;
    line-height: 13px;
    font-weight: bold;
    margin-bottom: 6px;
  }
  &-value {
  }

  &:first-of-type {
    .embedded-description-column {
      padding-top: 0;
    }
  }
}
.ant-table-expanded-row > td:nth-child(2) {
  padding: 0;
}

@import "responsive";

.d-flex {
  display: flex;
}
.d-flex-dir-column {
  flex-direction: column;
}
.d-xs-flex {
  .media-phone({display: flex;});

  &-dir-column {
    .media-phone({flex-direction: column;});
  }
  &-dir-column-reverse {
    .media-phone({flex-direction: column-reverse;});
  }
  &-dir-row {
    .media-phone({flex-direction: row;});
  }
}

.d-md-flex {
  .media-tablet({display: flex;});
  &-dir-column {
    .media-tablet({flex-direction: column;});
  }
  &-dir-column-reverse {
    .media-tablet({flex-direction: column-reverse;});
  }
  &-dir-row {
    .media-tablet({flex-direction: row;});
  }
}

.d-lg-flex {
  .media-desktop({display: flex});
  &-dir-column {
    .media-desktop({flex-direction: column;});
  }
  &-dir-column-reverse {
    .media-desktop({flex-direction: column-reverse;});
  }
  &-dir-row {
    .media-desktop({flex-direction: row;});
  }
}

.d-none {
  display: none !important;
}

.d-block {
  display: block;
}

.d-lg-none {
  .media-desktop({display: none !important;});
}
.d-md-none {
  .media-tablet({display: none !important});
}
.d-xs-none {
  .media-phone({display: none !important});
}

.d-lg-block {
  .media-desktop({display: block !important});
}
.d-md-block {
  .media-tablet({display: block !important});
}
.d-xs-block {
  .media-phone({display: block !important});
}

.ant-layout-header {
  position: relative;
  min-height: 90px;
  background-color: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  padding: 0;
  font-size: 16px;
  font-family: "Bw Modelica";
  font-weight: 500;
  z-index: 1000001;
  border-bottom: 1px solid #cdd1d9;
}
@media all and (max-width: 1200px) {
  .ant-layout-header {
    padding-right: 40px;
    padding-left: 40px;
  }
}
@media screen and (min-width: 992px) {
  .ant-layout-header {
    border-bottom: none;
  }
}
.ant-layout-header .brand-logo {
  min-width: 153px;
  min-height: 42px;
  display: block;
  background-image: url("../../styles/images/logo.svg");
  background-repeat: no-repeat;
  background-size: 100%;
}
.ant-layout-header .logout-link {
  font-size: 14px;
  line-height: 17px;
  position: absolute;
  right: 15px;
  color: #3d3d46;
  text-decoration: underline;
}
.ant-layout-header .menu-trigger {
  position: absolute;
  left: 15px;
  top: calc(50% - 13px);
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .ant-layout-header .menu-trigger {
    left: 35px;
  }
}
.ant-drawer.mobile-menu {
  transition: opacity 0.2s linear;
  opacity: 0;
}
.ant-drawer.mobile-menu.ant-drawer-open {
  opacity: 1;
}
.ant-drawer.mobile-menu.ant-drawer-top {
  transform: translateY(90px);
}
.ant-drawer.mobile-menu.ant-drawer-top .ant-drawer-content-wrapper {
  height: auto !important;
}
.ant-drawer.mobile-menu.ant-drawer-top .ant-drawer-body {
  padding-left: 31px;
  padding-top: 0;
  height: calc(100vh - 90px);
  overflow: scroll;
}
.ant-drawer.mobile-menu.ant-drawer-top .ant-drawer-body .ant-menu-inline {
  border-right: none;
}
.ant-drawer.mobile-menu.ant-drawer-top .ant-drawer-body .ant-menu-inline .ant-menu-item {
  margin: 0;
  width: auto;
  display: table;
}
.ant-drawer.mobile-menu.ant-drawer-top .ant-drawer-body .ant-menu-inline .ant-menu-item,
.ant-drawer.mobile-menu.ant-drawer-top .ant-drawer-body .ant-menu-inline .ant-menu-submenu-title {
  padding-left: 4px !important;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
}
.ant-drawer.mobile-menu.ant-drawer-top .ant-drawer-body .ant-menu-inline .ant-menu-item-selected {
  background-color: transparent;
  border-bottom: 3px solid #837ae9;
}
.ant-drawer.mobile-menu.ant-drawer-top .ant-drawer-body .ant-menu-inline .ant-menu-item-selected:after {
  content: none;
}
.ant-drawer.mobile-menu.ant-drawer-top .ant-drawer-body .ant-menu-inline .ant-menu-submenu-inline .ant-menu-item {
  margin-left: 15px !important;
}
.ant-drawer.mobile-menu.ant-drawer-top .ant-drawer-body .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  top: 10px;
}
.ant-menu-horizontal {
  font-size: 16px;
  font-family: "Bw Modelica";
  font-weight: 500;
  line-height: 87px;
  border: none;
}
.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-submenu:hover,
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu-active,
.ant-menu-horizontal > .ant-menu-item-open,
.ant-menu-horizontal > .ant-menu-submenu-open,
.ant-menu-horizontal > .ant-menu-item-selected,
.ant-menu-horizontal > .ant-menu-submenu-selected {
  border-bottom-width: 3px;
}
.ant-menu-horizontal > .ant-menu-item,
.ant-menu-horizontal > .ant-menu-submenu {
  border-bottom: 3px solid transparent;
}
.ant-menu-horizontal > .ant-menu-item.ant-menu-item-selected,
.ant-menu-horizontal > .ant-menu-submenu.ant-menu-item-selected {
  border-bottom-color: #837ae9;
}
.ant-dropdown {
  z-index: 10000000;
}
.ant-menu-item a {
  color: #3d3d46;
}

.status-name .container {
  overflow: hidden;
  width: 100%;
}
.status-name .box {
  width: 100%;
  white-space: nowrap;
}
.status-name .box div {
  display: inline-block;
}
.status-name .status-indicator {
  vertical-align: middle;
  margin-right: 10px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.status-name .status-indicator-approved {
  background: #69e283;
}
.status-name .status-indicator-pending_approval {
  background: #70baff;
}
.status-name .status-indicator-declined {
  background: #fa888f;
}
.status-name .status-indicator-removed {
  background: #cdd1d9;
}
.status-name .status-indicator-reinstated {
  background: #696b6d;
}
.status-name .status-indicator-reapplied {
  background: #ffd600;
}
.status-name .status-indicator-on_re_certification {
  background: #ea88fa;
}
.status-name .status-indicator-pending_recertification {
  background: #ea88fa;
}
.status-name .status-icon:hover path,
.status-name .status-icon:hover circle {
  stroke: #3D3D46;
  transition: all 0.2s linear;
}

.ant-message {
  z-index: 10000000;
}
* {
  //font-variant: common-ligatures !important;
  font-feature-settings: normal !important;
}
.ant-table {
  font-variant: common-ligatures !important;
}

.ant-select-dropdown-menu {
  .ant-spin-spinning {
    width: 100%;
    text-align: center;
  }
}
.ant-btn-primary {
  font-weight: bold;
}

.ant-btn-secondary {
  border: 1px solid @primary-color;
  color: @primary-color;
  font-weight: 500;
  &:hover,
  &:focus {
    background-color: #f1f0ff;
    border: 1px solid @primary-color;
    color: @primary-color;
  }

  &:active {
    background-color: #f1f0ff;
    border: 1px solid #5e55c2;
    color: #5e55c2;
  }
}

.ant-btn-ghost {
  border: 1px solid @btn-primary-bg;
  color: @btn-primary-bg;
  font-weight: 500;
  &:hover,
  &:focus {
    background-color: #fff;
    border: 1px solid @btn-primary-bg-hover;
    color: @btn-primary-bg-hover;
  }

  &:active {
    border: 1px solid @btn-primary-bg-active;
    color: @btn-primary-bg-active;
  }
}

button[disabled].ant-btn-ghost {
  background-color: @input-disabled-bg !important;
  border: 1px solid @text-gray !important;
  color: @text-gray !important;
}

.action-button {
  padding: 0 16px;
  display: flex;
  align-items: center;
  line-height: 0;
  border: 1px solid @primary-color;
  background-color: @primary-color;
  color: #fff;
  .anticon {
    margin-right: 10px;
  }

  &:hover,
  &:focus,
  &:active {
    border: 1px solid #5e55c2;
    background-color: #5e55c2;
    color: #fff;
  }
}

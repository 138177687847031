@import "../../styles/responsive";
@import "../../styles/ant-theme";

.login-form-container {
  max-width: 390px;
  margin: 0 auto;

  .ant-card-head {
    font-size: 24px;
    line-height: 29px;
    font-weight: bold;
    border-bottom: none;
    &-title {
      padding: 30px 0 30px;
      text-align: center;

      .media-phone({padding: 20px 0 15px; font-size: 20px; line-height: 24px;});
    }
  }
  .ant-card-body {
    padding: 0 48px 30px;
    .media-phone({padding: 0 27px 30px;});
  }

  .anticon {
    margin-right: 8px;
  }

  button {
    margin-top: 22px;
    font-weight: bold;
  }

  .register-row {
    margin-top: 50px;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    .media-phone({font-size: 12px; line-height: 14px; margin-top: 40px;});
  }

  .email-row {
    width: 80%;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    font-weight: 500;
    margin-bottom: 10px;
    display: inline-block;
    .media-phone({font-size: 12px; line-height: 14px; margin-top: 40px;});
  }

  .forgot-row {
    width: 100%;
    display: block;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
  }

  .forgot-password-row {
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 26px;
    margin-top: 20px;
    .media-phone({font-size: 10px; line-height: 12px; margin-bottom: 40px;});
  }

  .back-to-login {
    display: flex;
    line-height: 14px;
    font-weight: bold;
    text-decoration: none;
    svg {
      margin-right: 5px;
    }
  }

  a {
    color: @text-color;
    text-decoration: underline;
  }

  .ant-input-affix-wrapper .ant-input:not(:last-child) {
    padding-right: 70px;
  }
}

@primary-color: #837ae9;@text-color: #3d3d46;@text-gray: #a2a7b0;@heading-color: @text-color;@base-disabled-color: #f0f0f0;@base-disabled-text-color: #696b6d;@disabled-bg: @base-disabled-color;@gray: #cdd1d9;@font-size-lg: 16px;@border-color-base: @text-gray;@controls-border: @gray;@border-color-split: @controls-border;@card-radius: 6px;@card-shadow: 0px 10px 20px rgba(65, 39, 70, 0.1);@card-padding-base: 30px;@btn-height-base: 30px;@btn-padding-base: 0 10px;@btn-primary-bg: #ea5353;@btn-primary-bg-hover: #c74545;@btn-primary-bg-active: #983131;@btn-disable-border: @base-disabled-color;@btn-padding-lg: 0 32px;@btn-height-lg: 42px;@btn-default-color: @border-color-base;@input-border-color: @controls-border;@select-border-color: @controls-border;@input-height-lg: 42px;@input-disabled-bg: #fafafa;@page-header-padding-horizontal: 0;@zindex-picker: 1000001;@table-header-bg: #f6f7fb;@table-row-hover-bg: #f6f7fb;@badge-dot-size: 10px;@status-padding-top-bottom: 5px;@status-padding-left-right: 10px;@checkbox-padding-top: 8px;@popup-menu-top-bottom: 10px;@popup-menu-left-right: 21px;@pending-color: #a2a7b0;@radio-wrapper-border-color: #a8a2f0;@switch-checked-bg: #69E283;@switch-unchecked-bg: @gray;
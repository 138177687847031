// ===========================================
// Breakpoints
// ===========================================
@screen-phone: 576px;
@screen-tablet: 768px;
@screen-desktop: 992px;

// ===========================================
// Responsive Mixins
// ===========================================
.media-phone(@rules) {
  @media screen and (max-width: @screen-phone - 1) {
    @rules();
  }
}

.media-tablet(@rules) {
  @media screen and (min-width: (@screen-phone + 1px)) and (max-width: (@screen-desktop - 1px)) {
    @rules();
  }
}

.media-desktop(@rules) {
  @media screen and (min-width: @screen-desktop) {
    @rules();
  }
}

.media-custom-max(@width, @rules) {
  @media screen and (max-width: @width) {
    @rules();
  }
}

.media-custom-min(@width, @rules) {
  @media screen and (min-width: @width) {
    @rules();
  }
}

// Greater Than
.media-gt-phone(@rules) {
  @media (min-width: (@screen-phone + 1px)) {
    @rules();
  }
}

.media-gt-tablet(@rules) {
  @media (min-width: (@screen-tablet + 1px)) {
    @rules();
  }
}

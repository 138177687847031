.status-name {
  .container {
    overflow: hidden;
    width: 100%;
  }
  .box {
    width: 100%;
    white-space: nowrap;
  }
  .box div {
    display: inline-block;
  }
  .status-indicator {
    vertical-align: middle;
    margin-right: 10px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    &-approved {
      background: #69e283;
    }
    &-pending_approval {
      background: #70baff;
    }
    &-declined {
      background: #fa888f;
    }
    &-removed {
      background: #cdd1d9;
    }
    &-reinstated {
      background: #696b6d;
    }
    &-reapplied {
      background: #ffd600;
    }
    &-on_re_certification {
      background: #ea88fa;
    }
    &-pending_recertification {
      background: #ea88fa;
    }
  }
  .status-icon {
    &:hover {
      path, circle {
        stroke: #3D3D46;
        transition: all 0.2s linear;
      }
    }
  }
}

@primary-color: #837ae9;@text-color: #3d3d46;@text-gray: #a2a7b0;@heading-color: @text-color;@base-disabled-color: #f0f0f0;@base-disabled-text-color: #696b6d;@disabled-bg: @base-disabled-color;@gray: #cdd1d9;@font-size-lg: 16px;@border-color-base: @text-gray;@controls-border: @gray;@border-color-split: @controls-border;@card-radius: 6px;@card-shadow: 0px 10px 20px rgba(65, 39, 70, 0.1);@card-padding-base: 30px;@btn-height-base: 30px;@btn-padding-base: 0 10px;@btn-primary-bg: #ea5353;@btn-primary-bg-hover: #c74545;@btn-primary-bg-active: #983131;@btn-disable-border: @base-disabled-color;@btn-padding-lg: 0 32px;@btn-height-lg: 42px;@btn-default-color: @border-color-base;@input-border-color: @controls-border;@select-border-color: @controls-border;@input-height-lg: 42px;@input-disabled-bg: #fafafa;@page-header-padding-horizontal: 0;@zindex-picker: 1000001;@table-header-bg: #f6f7fb;@table-row-hover-bg: #f6f7fb;@badge-dot-size: 10px;@status-padding-top-bottom: 5px;@status-padding-left-right: 10px;@checkbox-padding-top: 8px;@popup-menu-top-bottom: 10px;@popup-menu-left-right: 21px;@pending-color: #a2a7b0;@radio-wrapper-border-color: #a8a2f0;@switch-checked-bg: #69E283;@switch-unchecked-bg: @gray;
.authorization-loader {
  position: absolute;
  top: 50%;
  left: 50%;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 100;
}
.terms-and-conditions {
  .ant-page-header {
    padding-bottom: 40px !important;
  }
  .header {
    margin-bottom: 1.25rem !important;
  }
  .question {
    font-size: 16px;
    font-style: normal;
    margin-bottom: 1.25rem !important;
    font-weight: 500;
  }

  .content-wrapper {
    position: relative;
  }
  .content,
  .intro {
    line-height: 1.5rem;
    font-weight: 400;
    margin-bottom: 50px !important;
  }
  .footer-section {
    display: flex;
    justify-content: space-between;
  }
  .company-name {
    border-top: 1px solid #3d3d46;
    padding-top: 1em;
  }

  .main-wrapper {
    display: flex;
  }
  .affix-wrapper {
    padding-left: 60px;
    .ant-affix {
      padding-top: 20px;
    }
  }
  .nav-link {
    border: none;
    color: #837ae9;
    margin-bottom: 10px;
  }
  .button {
    border: none;
    color: #837ae9;
    padding: 5px 15px 5px 15px;
    margin-bottom: 10px;
    font-size: 12px;
  }
  .buttons-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .button:hover,
  .active {
    background-color: #837ae9;
    color: white;
    font-weight: bold;
    border-color: transparent;
    border-radius: 3px;
  }
  .mobile-nav-button {
    display: none;
  }

  @media screen and (max-width: 768px) {
    .mobile-nav-button {
      display: initial;
    }
    .button {
      font-size: 24px;
    }
    .desktop-nav-menu,
    .affix-wrapper {
      display: none;
    }
    .ant-drawer-title {
      font-size: 24px;
    }
  }
}

@primary-color: #837ae9;@text-color: #3d3d46;@text-gray: #a2a7b0;@heading-color: @text-color;@base-disabled-color: #f0f0f0;@base-disabled-text-color: #696b6d;@disabled-bg: @base-disabled-color;@gray: #cdd1d9;@font-size-lg: 16px;@border-color-base: @text-gray;@controls-border: @gray;@border-color-split: @controls-border;@card-radius: 6px;@card-shadow: 0px 10px 20px rgba(65, 39, 70, 0.1);@card-padding-base: 30px;@btn-height-base: 30px;@btn-padding-base: 0 10px;@btn-primary-bg: #ea5353;@btn-primary-bg-hover: #c74545;@btn-primary-bg-active: #983131;@btn-disable-border: @base-disabled-color;@btn-padding-lg: 0 32px;@btn-height-lg: 42px;@btn-default-color: @border-color-base;@input-border-color: @controls-border;@select-border-color: @controls-border;@input-height-lg: 42px;@input-disabled-bg: #fafafa;@page-header-padding-horizontal: 0;@zindex-picker: 1000001;@table-header-bg: #f6f7fb;@table-row-hover-bg: #f6f7fb;@badge-dot-size: 10px;@status-padding-top-bottom: 5px;@status-padding-left-right: 10px;@checkbox-padding-top: 8px;@popup-menu-top-bottom: 10px;@popup-menu-left-right: 21px;@pending-color: #a2a7b0;@radio-wrapper-border-color: #a8a2f0;@switch-checked-bg: #69E283;@switch-unchecked-bg: @gray;
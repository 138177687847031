.ant-calendar-picker-icon {
  transform: translate(0, -15%);
}

.date-range-picker {
  .ant-calendar-picker-input {
    padding-left: 9px;
    padding-right: 9px;
    .ant-calendar-range-picker-input {
      width: 39%;
      text-align: left;
    }
    .ant-calendar-range-picker-separator {
      position: relative;
      margin-left: 20px;
      margin-right: 13px;
      line-height: 2;
      vertical-align: middle;
      &:before {
        content: "";
        min-width: 19px;
        min-height: 19px;
        position: absolute;
        display: block;
        right: 26px;
        top: 4px;
        background-image: url("../icons/calendar-purple.svg");
      }
    }
  }
}

.time-range-picker {
  .ant-calendar-range-picker-separator {
    &:before {
      background-repeat: no-repeat;
      background-image: url("../icons/clock.svg") !important;
    }
  }
}

.ant-time-picker-large .ant-time-picker-input {
  font-size: 14px;
  line-height: 17px;
}

.time-range-picker-dropdown {
  .ant-calendar-footer-extra {
    width: 100%;
    text-align: right;
  }
  .ant-calendar-time-picker-btn,
  .ant-calendar-ok-btn {
    display: none !important;
  }
  .ant-calendar-range-middle {
    display: none;
  }
  .ant-calendar-header {
    display: none;
  }
  .ant-calendar-time-picker {
    top: 28px !important;
  }
}

.ant-upload.ant-upload-select {
  display: block;
  .ant-upload {
    display: flex;
    position: relative;
    button {
      padding: 0 16px;
      position: absolute;
      right: 0;
      border: 1px solid #837ae9;
      box-sizing: border-box;
      border-radius: 4px;
      color: #837ae9;
      font-weight: bold;
      background-color: #fff;
    }
  }
}
.ant-upload-list-item {
  .ant-upload-list-item-info {
    .anticon {
      display: none;
    }
    .ant-upload-list-item-name {
      padding-left: 0;
    }
  }
  &:hover .ant-upload-list-item-info {
    background-color: transparent;
  }
}

.file-uploader-delete-handler {
  font-size: 10px;
  color: #b5b4bd;
  cursor: pointer;
  position: absolute;
  right: 100px;
  top: calc(50% - 5px);
  visibility: hidden;
  &:hover {
    color: #3d3d46;
  }
}

.ant-upload-select {
  &:hover {
    .file-uploader-delete-handler {
      visibility: visible;
    }
  }
}

@primary-color: #837ae9;@text-color: #3d3d46;@text-gray: #a2a7b0;@heading-color: @text-color;@base-disabled-color: #f0f0f0;@base-disabled-text-color: #696b6d;@disabled-bg: @base-disabled-color;@gray: #cdd1d9;@font-size-lg: 16px;@border-color-base: @text-gray;@controls-border: @gray;@border-color-split: @controls-border;@card-radius: 6px;@card-shadow: 0px 10px 20px rgba(65, 39, 70, 0.1);@card-padding-base: 30px;@btn-height-base: 30px;@btn-padding-base: 0 10px;@btn-primary-bg: #ea5353;@btn-primary-bg-hover: #c74545;@btn-primary-bg-active: #983131;@btn-disable-border: @base-disabled-color;@btn-padding-lg: 0 32px;@btn-height-lg: 42px;@btn-default-color: @border-color-base;@input-border-color: @controls-border;@select-border-color: @controls-border;@input-height-lg: 42px;@input-disabled-bg: #fafafa;@page-header-padding-horizontal: 0;@zindex-picker: 1000001;@table-header-bg: #f6f7fb;@table-row-hover-bg: #f6f7fb;@badge-dot-size: 10px;@status-padding-top-bottom: 5px;@status-padding-left-right: 10px;@checkbox-padding-top: 8px;@popup-menu-top-bottom: 10px;@popup-menu-left-right: 21px;@pending-color: #a2a7b0;@radio-wrapper-border-color: #a8a2f0;@switch-checked-bg: #69E283;@switch-unchecked-bg: @gray;
ul.hr {
  margin-bottom: 30px;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
}
ul.hr.tagged-nav-bar-compact {
  margin-bottom: 0;
}
ul.hr li {
  margin-right: 12px;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  padding: 5px 10px;
}
ul.hr li.tag-nav-bar {
  height: 26px;
  margin-bottom: 12px;
  position: relative;
}
ul.hr li.tag-nav-bar-selected {
  font-weight: bold;
  background: #f1f0ff;
  border-radius: 6px;
}
ul.hr li.extra-content {
  padding: 0 0 0 10px;
}
.tagged-nav-bar-compact  .tag-nav-bar {
  margin-bottom: 0 !important;
}
.pending-row {
  color: #a2a7b0;
}
.pending-row .ant-checkbox {
  padding-top: 8px;
}
.normal-row .ant-checkbox {
  padding-top: 8px;
}
.tag-color {
  color: #3d3d46 !important;
}
.tag-color-selected {
  color: #837ae9 !important;
}
th {
  font-size: 12px;
  font-weight: bold;
  line-height: 14px;
}
.row-selection {
  padding-top: 5px;
}
.ant-page-header .ant-page-header-title-view-title {
  font-weight: 800;
}
.dropdown {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  margin: 28px 0 20px 0;
}
.phone-number-column,
.license-expiry-date-column {
  min-width: 125px;
}
.license-class-column {
  min-width: 125px;
}
.destination-arrow.status-col::before {
  width: 65%;
  left: 32%;
}
.ant-table .ant-table-tbody > tr td.tracking-icon-col {
  padding: 0 !important;
}
.ant-table .ant-table-tbody > tr td.tracking-icon-col span.tracking-icon {
  margin: 0 10px 0 16px;
}
.ant-table .ant-table-tbody > tr td.tracking-icon-col + td {
  padding-left: 0 !important;
}
.ant-table .ant-table-thead > tr th.tracking-icon-col {
  padding: 0 !important;
  padding-left: 16px !important;
}
.ant-table .ant-table-thead > tr th.tracking-icon-col + th {
  padding-left: 0 !important;
}
.tracking-modal .ant-modal-content {
  min-height: 694px;
}
.tracking-modal .ant-modal-content .ant-modal-header {
  padding: 24px !important;
  padding-bottom: 19px !important;
}
.tracking-modal .ant-modal-content .ant-modal-header .ant-modal-title {
  font-size: 16px;
  line-height: 19px;
  text-align: left;
}
.tracking-modal .ant-modal-content .ant-modal-body {
  padding: 0 24px 24px !important;
}
.tracking-modal .close-icon {
  position: absolute;
  width: 24px;
  height: 24px;
  top: 24px;
  right: 24px;
}

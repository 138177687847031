.ant-layout-footer {
  font-size: 12px;
  line-height: 170%;
  color: #fff;
  padding: 16px 0;
}
@media screen and (max-width: 575px) {
  .ant-layout-footer {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.ant-layout-footer .ant-row-flex {
  max-width: 1200px;
}
.ant-layout-footer .footer-col-header {
  font-weight: bold;
}
.ant-layout-footer .footer-col-content ul {
  list-style: none;
  padding-left: 0;
  margin-top: 10px;
}
.ant-layout-footer .footer-col-content ul li {
  line-height: 22px;
}
.ant-layout-footer address {
  margin-top: 35px;
}
.ant-layout-footer a {
  color: #ffffff;
}
.ant-layout-footer a:hover {
  color: #e6e6e6;
}

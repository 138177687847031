.login-form-container {
  max-width: 390px;
  margin: 0 auto;
}
.login-form-container .ant-card-head {
  font-size: 24px;
  line-height: 29px;
  font-weight: bold;
  border-bottom: none;
}
.login-form-container .ant-card-head-title {
  padding: 30px 0 30px;
  text-align: center;
}
@media screen and (max-width: 575px) {
  .login-form-container .ant-card-head-title {
    padding: 20px 0 15px;
    font-size: 20px;
    line-height: 24px;
  }
}
.login-form-container .ant-card-body {
  padding: 0 48px 30px;
}
@media screen and (max-width: 575px) {
  .login-form-container .ant-card-body {
    padding: 0 27px 30px;
  }
}
.login-form-container .anticon {
  margin-right: 8px;
}
.login-form-container button {
  margin-top: 22px;
  font-weight: bold;
}
.login-form-container .register-row {
  margin-top: 50px;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
}
@media screen and (max-width: 575px) {
  .login-form-container .register-row {
    font-size: 12px;
    line-height: 14px;
    margin-top: 40px;
  }
}
.login-form-container .email-row {
  width: 80%;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  font-weight: 500;
  margin-bottom: 10px;
  display: inline-block;
}
@media screen and (max-width: 575px) {
  .login-form-container .email-row {
    font-size: 12px;
    line-height: 14px;
    margin-top: 40px;
  }
}
.login-form-container .forgot-row {
  width: 100%;
  display: block;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
}
.login-form-container .forgot-password-row {
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 26px;
  margin-top: 20px;
}
@media screen and (max-width: 575px) {
  .login-form-container .forgot-password-row {
    font-size: 10px;
    line-height: 12px;
    margin-bottom: 40px;
  }
}
.login-form-container .back-to-login {
  display: flex;
  line-height: 14px;
  font-weight: bold;
  text-decoration: none;
}
.login-form-container .back-to-login svg {
  margin-right: 5px;
}
.login-form-container a {
  color: #3d3d46;
  text-decoration: underline;
}
.login-form-container .ant-input-affix-wrapper .ant-input:not(:last-child) {
  padding-right: 70px;
}

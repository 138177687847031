.btn-more {
  width: 34px;
  height: 22px;
  background-repeat: no-repeat;
  border: 0;
  box-shadow: none;
  background-color: transparent;
  padding: 0;
}
.btn-more:hover,
.btn-more:focus {
  width: 34px;
  height: 22px;
  background-repeat: no-repeat;
  border: 0;
}
.popup-menu-item {
  font-weight: 500;
  line-height: 17px;
  align-items: flex-start;
  padding: 10px 21px 10px;
}
.popup-menu-item:first-child {
  font-weight: 500;
  padding-top: 20px;
}
.popup-menu-item:last-child {
  padding-bottom: 20px;
}
.popup-menu-item-pending {
  font-weight: 500;
  padding: 10px 21px 10px 21px;
  color: #a2a7b0;
  pointer-events: none;
}

@import "../../styles/responsive";
@import "../../styles/ant-theme";

@play-btn-size: 120px;

.ant-layout {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  background-color: white;

  .ant-layout-content {
    &.home {
      background-repeat: no-repeat;
      background-size: 100%;
      border-top: 0;
      font-size: 16px;
      line-height: 180%;

      .main-img {
        border-radius: 4px;
        margin-bottom: 10%;

        background-repeat: no-repeat;
        background-image: url("../../styles/images/home-page.jpg");
        background-size: cover;
        .media-phone(
          {margin-top: -40px; max-height: 336px; padding-top: 336px;
            margin-bottom: calc(10% + 427px) ;}
        );
        .media-tablet(
          {min-height: 680px; background-size: 170%; background-position-x: 35%;}
        );
        .media-desktop(
          {min-height: 680px; padding-bottom: 310px; margin-top: -50px;}
        );
      }
      .btn-create-account {
        font-size: 20px;
        font-weight: bold;
        line-height: 45px;
        padding: 10px 40px;
        .media-phone({display: block; line-height: 24px;});
        .media-tablet({padding: 10px 20px; font-weight: 500; ;});
      }
      .get-quote-bg {
        width: 100vw;
        position: relative;
        left: 50%;
        right: 50%;
        margin-left: -50vw;
        margin-right: -50vw;
        min-height: 457px;
        background-repeat: no-repeat;
        background-size: cover;
        background-image: url("../../styles/images/home-get-quote.svg");

        .media-phone(
          {background-image: url("../../styles/images/home-get-quote-mobile.svg")
            ;}
        );
        .media-tablet(
          {background-image: url("../../styles/images/home-get-quote-tablet.svg")
            ;}
        );
      }
      .h1-main {
        margin-top: 50px;
        font-family: "Bw Modelica";
        font-weight: 800;
        font-size: 54px;
        line-height: 120%;
        white-space: pre-line;
        .media-phone(
          {margin-bottom: 20px; margin-top: 20px; font-size: 42px; line-height: 120%;}
        );
      }
      .h1-quote {
        margin-top: 90px;
        font-family: "Bw Modelica";
        font-weight: 800;
        font-size: 42px;
        line-height: 50px;
        text-align: center;
        color: white;
        // .media-phone({margin-top: 20px;});
      }
      .h1-what-we-do {
        // margin-top: 20px;
        font-family: "Bw Modelica";
        font-weight: 800;
        font-size: 30px;
        line-height: 160%;
        .media-desktop({padding-bottom: 10px;});
      }
      .p-main {
        padding-bottom: 30px;
        white-space: pre-line;
        .media-phone({padding-bottom: 16px;});
        .media-tablet({width: 75%;});
      }
      .p-get-quote {
        color: white;
        font-size: 22px;
        line-height: 170%;
        font-weight: 500;
        text-align: center;
        padding-bottom: 30px;
      }
      .btn-get-quote {
        font-weight: bold;
        font-size: 22px;
        line-height: 26px;
        width: 231px;
        height: 48px;
        border-radius: 8px;
        border-width: 2px;
      }
      .button {
        margin-top: 20px;
      }
      .section-title {
        font-family: "Bw Modelica";
        font-weight: 800;
        font-size: 30px;
        line-height: 36px;
        text-decoration: underline;
        margin-top: 20px;
      }
      .card-img {
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 4px;
        height: 230px;
        &-shipper {
          background-image: url("../../styles/images/shipper.jpg");
        }
        &-agent {
          background-image: url("../../styles/images/agent.jpg");
        }
        &-truck-owner {
          background-image: url("../../styles/images/truck-owner.jpg");
        }
      }

      .video-what-we-do {
        margin-bottom: 20px;
        min-height: 382px;
        background-repeat: no-repeat;
        border-radius: 4px;
        position: relative;

        img,
        picture {
          object-fit: cover;
          border-radius: 5px;
          width: 100%;
          .media-phone({min-height: 250px; height: 100%;});
        }
        .overlay {
          position: absolute;
          top: calc(50% - @play-btn-size / 2);
          left: calc(50% - @play-btn-size / 2);
          button {
            height: @play-btn-size;
            width: @play-btn-size;
            svg {
              width: 100%;
            }
          }
        }
        .media-phone(
          {min-height: 250px; .overlay {top:
            calc(50% - (@play-btn-size - 20px) / 2) ; left:
            calc(50% - (@play-btn-size - 20px) / 2) ; button {height: @play-btn-size -
            20px; width: @play-btn-size - 20px;}}}
        );
        .media-desktop({margin-bottom: 50px;});
      }
      .media-tablet(
        {padding: 25px 35px 40px; background-size: cover; background-position-x:
          50%;}
      );
      .media-phone({padding: 40px 15px 80px; background-size: cover;});
    }
  }
}

@primary-color: #837ae9;@text-color: #3d3d46;@text-gray: #a2a7b0;@heading-color: @text-color;@base-disabled-color: #f0f0f0;@base-disabled-text-color: #696b6d;@disabled-bg: @base-disabled-color;@gray: #cdd1d9;@font-size-lg: 16px;@border-color-base: @text-gray;@controls-border: @gray;@border-color-split: @controls-border;@card-radius: 6px;@card-shadow: 0px 10px 20px rgba(65, 39, 70, 0.1);@card-padding-base: 30px;@btn-height-base: 30px;@btn-padding-base: 0 10px;@btn-primary-bg: #ea5353;@btn-primary-bg-hover: #c74545;@btn-primary-bg-active: #983131;@btn-disable-border: @base-disabled-color;@btn-padding-lg: 0 32px;@btn-height-lg: 42px;@btn-default-color: @border-color-base;@input-border-color: @controls-border;@select-border-color: @controls-border;@input-height-lg: 42px;@input-disabled-bg: #fafafa;@page-header-padding-horizontal: 0;@zindex-picker: 1000001;@table-header-bg: #f6f7fb;@table-row-hover-bg: #f6f7fb;@badge-dot-size: 10px;@status-padding-top-bottom: 5px;@status-padding-left-right: 10px;@checkbox-padding-top: 8px;@popup-menu-top-bottom: 10px;@popup-menu-left-right: 21px;@pending-color: #a2a7b0;@radio-wrapper-border-color: #a8a2f0;@switch-checked-bg: #69E283;@switch-unchecked-bg: @gray;
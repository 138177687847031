.ant-pagination-disabled {
  color: @controls-border !important;
}

.ant-pagination-prev {
  margin-right: 13px !important;
  color: @text-gray !important;
  &:not(.ant-pagination-disabled):hover {
    color: @text-color !important;
  }
}

.ant-pagination-next {
  color: @text-gray !important;
  &:not(.ant-pagination-disabled):hover {
    color: @text-color !important;
  }
}

.ant-pagination-item a {
  color: @text-gray !important;
  &:hover {
    color: @text-color !important;
  }
}

.ant-pagination-item {
  margin-right: 16px !important;
}

.ant-pagination-item-active {
  border-color: @text-color !important;
  a {
    color: @text-color !important;
  }
}

.d-flex {
  display: flex;
}
.d-flex-dir-column {
  flex-direction: column;
}
@media screen and (max-width: 575px) {
  .d-xs-flex {
    display: flex;
  }
}
@media screen and (max-width: 575px) {
  .d-xs-flex-dir-column {
    flex-direction: column;
  }
}
@media screen and (max-width: 575px) {
  .d-xs-flex-dir-column-reverse {
    flex-direction: column-reverse;
  }
}
@media screen and (max-width: 575px) {
  .d-xs-flex-dir-row {
    flex-direction: row;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .d-md-flex {
    display: flex;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .d-md-flex-dir-column {
    flex-direction: column;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .d-md-flex-dir-column-reverse {
    flex-direction: column-reverse;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .d-md-flex-dir-row {
    flex-direction: row;
  }
}
@media screen and (min-width: 992px) {
  .d-lg-flex {
    display: flex;
  }
}
@media screen and (min-width: 992px) {
  .d-lg-flex-dir-column {
    flex-direction: column;
  }
}
@media screen and (min-width: 992px) {
  .d-lg-flex-dir-column-reverse {
    flex-direction: column-reverse;
  }
}
@media screen and (min-width: 992px) {
  .d-lg-flex-dir-row {
    flex-direction: row;
  }
}
.d-none {
  display: none !important;
}
.d-block {
  display: block;
}
@media screen and (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .d-md-none {
    display: none !important;
  }
}
@media screen and (max-width: 575px) {
  .d-xs-none {
    display: none !important;
  }
}
@media screen and (min-width: 992px) {
  .d-lg-block {
    display: block !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .d-md-block {
    display: block !important;
  }
}
@media screen and (max-width: 575px) {
  .d-xs-block {
    display: block !important;
  }
}
.font-italic {
  font-style: italic;
}
.font-regular {
  font-weight: normal;
}
.font-bolder {
  font-weight: 500;
}
.font-bold {
  font-weight: bold;
}
.text-underlined {
  text-decoration: underline;
}
.text-disabled {
  opacity: 0.3;
}
.text-left {
  text-align: left !important;
}
.text-rigth {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}
.text-common {
  font-size: 16px;
  line-height: 150%;
}
.text-black {
  color: #3d3d46 !important;
}
.text-gray {
  color: #a2a7b0 !important;
}
.text-white {
  color: #fff !important;
}
.text-label {
  font-size: 12px;
  line-height: 150%;
}
.text-normal {
  font-size: 14px;
  line-height: 17px;
}
.fs-54 {
  font-size: 54px !important;
}
.fs-53 {
  font-size: 53px !important;
}
.fs-52 {
  font-size: 52px !important;
}
.fs-51 {
  font-size: 51px !important;
}
.fs-50 {
  font-size: 50px !important;
}
.fs-49 {
  font-size: 49px !important;
}
.fs-48 {
  font-size: 48px !important;
}
.fs-47 {
  font-size: 47px !important;
}
.fs-46 {
  font-size: 46px !important;
}
.fs-45 {
  font-size: 45px !important;
}
.fs-44 {
  font-size: 44px !important;
}
.fs-43 {
  font-size: 43px !important;
}
.fs-42 {
  font-size: 42px !important;
}
.fs-41 {
  font-size: 41px !important;
}
.fs-40 {
  font-size: 40px !important;
}
.fs-39 {
  font-size: 39px !important;
}
.fs-38 {
  font-size: 38px !important;
}
.fs-37 {
  font-size: 37px !important;
}
.fs-36 {
  font-size: 36px !important;
}
.fs-35 {
  font-size: 35px !important;
}
.fs-34 {
  font-size: 34px !important;
}
.fs-33 {
  font-size: 33px !important;
}
.fs-32 {
  font-size: 32px !important;
}
.fs-31 {
  font-size: 31px !important;
}
.fs-30 {
  font-size: 30px !important;
}
.fs-29 {
  font-size: 29px !important;
}
.fs-28 {
  font-size: 28px !important;
}
.fs-27 {
  font-size: 27px !important;
}
.fs-26 {
  font-size: 26px !important;
}
.fs-25 {
  font-size: 25px !important;
}
.fs-24 {
  font-size: 24px !important;
}
.fs-23 {
  font-size: 23px !important;
}
.fs-22 {
  font-size: 22px !important;
}
.fs-21 {
  font-size: 21px !important;
}
.fs-20 {
  font-size: 20px !important;
}
.fs-19 {
  font-size: 19px !important;
}
.fs-18 {
  font-size: 18px !important;
}
.fs-17 {
  font-size: 17px !important;
}
.fs-16 {
  font-size: 16px !important;
}
.fs-15 {
  font-size: 15px !important;
}
.fs-14 {
  font-size: 14px !important;
}
.fs-13 {
  font-size: 13px !important;
}
.fs-12 {
  font-size: 12px !important;
}
.fs-11 {
  font-size: 11px !important;
}
.m-top-200 {
  margin-top: 200px !important;
}
@media screen and (min-width: 992px) {
  .m-lg-top-200 {
    margin-top: 200px !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .m-md-top-200 {
    margin-top: 200px !important;
  }
}
@media screen and (max-width: 576px) {
  .m-xs-top-200 {
    margin-top: 200px !important;
  }
}
.m-top-190 {
  margin-top: 190px !important;
}
@media screen and (min-width: 992px) {
  .m-lg-top-190 {
    margin-top: 190px !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .m-md-top-190 {
    margin-top: 190px !important;
  }
}
@media screen and (max-width: 576px) {
  .m-xs-top-190 {
    margin-top: 190px !important;
  }
}
.m-top-180 {
  margin-top: 180px !important;
}
@media screen and (min-width: 992px) {
  .m-lg-top-180 {
    margin-top: 180px !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .m-md-top-180 {
    margin-top: 180px !important;
  }
}
@media screen and (max-width: 576px) {
  .m-xs-top-180 {
    margin-top: 180px !important;
  }
}
.m-top-170 {
  margin-top: 170px !important;
}
@media screen and (min-width: 992px) {
  .m-lg-top-170 {
    margin-top: 170px !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .m-md-top-170 {
    margin-top: 170px !important;
  }
}
@media screen and (max-width: 576px) {
  .m-xs-top-170 {
    margin-top: 170px !important;
  }
}
.m-top-160 {
  margin-top: 160px !important;
}
@media screen and (min-width: 992px) {
  .m-lg-top-160 {
    margin-top: 160px !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .m-md-top-160 {
    margin-top: 160px !important;
  }
}
@media screen and (max-width: 576px) {
  .m-xs-top-160 {
    margin-top: 160px !important;
  }
}
.m-top-150 {
  margin-top: 150px !important;
}
@media screen and (min-width: 992px) {
  .m-lg-top-150 {
    margin-top: 150px !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .m-md-top-150 {
    margin-top: 150px !important;
  }
}
@media screen and (max-width: 576px) {
  .m-xs-top-150 {
    margin-top: 150px !important;
  }
}
.m-top-140 {
  margin-top: 140px !important;
}
@media screen and (min-width: 992px) {
  .m-lg-top-140 {
    margin-top: 140px !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .m-md-top-140 {
    margin-top: 140px !important;
  }
}
@media screen and (max-width: 576px) {
  .m-xs-top-140 {
    margin-top: 140px !important;
  }
}
.m-top-130 {
  margin-top: 130px !important;
}
@media screen and (min-width: 992px) {
  .m-lg-top-130 {
    margin-top: 130px !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .m-md-top-130 {
    margin-top: 130px !important;
  }
}
@media screen and (max-width: 576px) {
  .m-xs-top-130 {
    margin-top: 130px !important;
  }
}
.m-top-120 {
  margin-top: 120px !important;
}
@media screen and (min-width: 992px) {
  .m-lg-top-120 {
    margin-top: 120px !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .m-md-top-120 {
    margin-top: 120px !important;
  }
}
@media screen and (max-width: 576px) {
  .m-xs-top-120 {
    margin-top: 120px !important;
  }
}
.m-top-110 {
  margin-top: 110px !important;
}
@media screen and (min-width: 992px) {
  .m-lg-top-110 {
    margin-top: 110px !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .m-md-top-110 {
    margin-top: 110px !important;
  }
}
@media screen and (max-width: 576px) {
  .m-xs-top-110 {
    margin-top: 110px !important;
  }
}
.m-top-100 {
  margin-top: 100px !important;
}
@media screen and (min-width: 992px) {
  .m-lg-top-100 {
    margin-top: 100px !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .m-md-top-100 {
    margin-top: 100px !important;
  }
}
@media screen and (max-width: 576px) {
  .m-xs-top-100 {
    margin-top: 100px !important;
  }
}
.m-top-90 {
  margin-top: 90px !important;
}
@media screen and (min-width: 992px) {
  .m-lg-top-90 {
    margin-top: 90px !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .m-md-top-90 {
    margin-top: 90px !important;
  }
}
@media screen and (max-width: 576px) {
  .m-xs-top-90 {
    margin-top: 90px !important;
  }
}
.m-top-80 {
  margin-top: 80px !important;
}
@media screen and (min-width: 992px) {
  .m-lg-top-80 {
    margin-top: 80px !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .m-md-top-80 {
    margin-top: 80px !important;
  }
}
@media screen and (max-width: 576px) {
  .m-xs-top-80 {
    margin-top: 80px !important;
  }
}
.m-top-70 {
  margin-top: 70px !important;
}
@media screen and (min-width: 992px) {
  .m-lg-top-70 {
    margin-top: 70px !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .m-md-top-70 {
    margin-top: 70px !important;
  }
}
@media screen and (max-width: 576px) {
  .m-xs-top-70 {
    margin-top: 70px !important;
  }
}
.m-top-60 {
  margin-top: 60px !important;
}
@media screen and (min-width: 992px) {
  .m-lg-top-60 {
    margin-top: 60px !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .m-md-top-60 {
    margin-top: 60px !important;
  }
}
@media screen and (max-width: 576px) {
  .m-xs-top-60 {
    margin-top: 60px !important;
  }
}
.m-top-50 {
  margin-top: 50px !important;
}
@media screen and (min-width: 992px) {
  .m-lg-top-50 {
    margin-top: 50px !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .m-md-top-50 {
    margin-top: 50px !important;
  }
}
@media screen and (max-width: 576px) {
  .m-xs-top-50 {
    margin-top: 50px !important;
  }
}
.m-top-40 {
  margin-top: 40px !important;
}
@media screen and (min-width: 992px) {
  .m-lg-top-40 {
    margin-top: 40px !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .m-md-top-40 {
    margin-top: 40px !important;
  }
}
@media screen and (max-width: 576px) {
  .m-xs-top-40 {
    margin-top: 40px !important;
  }
}
.m-top-30 {
  margin-top: 30px !important;
}
@media screen and (min-width: 992px) {
  .m-lg-top-30 {
    margin-top: 30px !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .m-md-top-30 {
    margin-top: 30px !important;
  }
}
@media screen and (max-width: 576px) {
  .m-xs-top-30 {
    margin-top: 30px !important;
  }
}
.m-top-20 {
  margin-top: 20px !important;
}
@media screen and (min-width: 992px) {
  .m-lg-top-20 {
    margin-top: 20px !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .m-md-top-20 {
    margin-top: 20px !important;
  }
}
@media screen and (max-width: 576px) {
  .m-xs-top-20 {
    margin-top: 20px !important;
  }
}
.m-top-10 {
  margin-top: 10px !important;
}
@media screen and (min-width: 992px) {
  .m-lg-top-10 {
    margin-top: 10px !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .m-md-top-10 {
    margin-top: 10px !important;
  }
}
@media screen and (max-width: 576px) {
  .m-xs-top-10 {
    margin-top: 10px !important;
  }
}
.m-bottom-200 {
  margin-bottom: 200px !important;
}
@media screen and (min-width: 992px) {
  .m-lg-bottom-200 {
    margin-bottom: 200px !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .m-md-bottom-200 {
    margin-bottom: 200px !important;
  }
}
@media screen and (max-width: 576px) {
  .m-xs-bottom-200 {
    margin-bottom: 200px !important;
  }
}
.m-bottom-190 {
  margin-bottom: 190px !important;
}
@media screen and (min-width: 992px) {
  .m-lg-bottom-190 {
    margin-bottom: 190px !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .m-md-bottom-190 {
    margin-bottom: 190px !important;
  }
}
@media screen and (max-width: 576px) {
  .m-xs-bottom-190 {
    margin-bottom: 190px !important;
  }
}
.m-bottom-180 {
  margin-bottom: 180px !important;
}
@media screen and (min-width: 992px) {
  .m-lg-bottom-180 {
    margin-bottom: 180px !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .m-md-bottom-180 {
    margin-bottom: 180px !important;
  }
}
@media screen and (max-width: 576px) {
  .m-xs-bottom-180 {
    margin-bottom: 180px !important;
  }
}
.m-bottom-170 {
  margin-bottom: 170px !important;
}
@media screen and (min-width: 992px) {
  .m-lg-bottom-170 {
    margin-bottom: 170px !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .m-md-bottom-170 {
    margin-bottom: 170px !important;
  }
}
@media screen and (max-width: 576px) {
  .m-xs-bottom-170 {
    margin-bottom: 170px !important;
  }
}
.m-bottom-160 {
  margin-bottom: 160px !important;
}
@media screen and (min-width: 992px) {
  .m-lg-bottom-160 {
    margin-bottom: 160px !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .m-md-bottom-160 {
    margin-bottom: 160px !important;
  }
}
@media screen and (max-width: 576px) {
  .m-xs-bottom-160 {
    margin-bottom: 160px !important;
  }
}
.m-bottom-150 {
  margin-bottom: 150px !important;
}
@media screen and (min-width: 992px) {
  .m-lg-bottom-150 {
    margin-bottom: 150px !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .m-md-bottom-150 {
    margin-bottom: 150px !important;
  }
}
@media screen and (max-width: 576px) {
  .m-xs-bottom-150 {
    margin-bottom: 150px !important;
  }
}
.m-bottom-140 {
  margin-bottom: 140px !important;
}
@media screen and (min-width: 992px) {
  .m-lg-bottom-140 {
    margin-bottom: 140px !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .m-md-bottom-140 {
    margin-bottom: 140px !important;
  }
}
@media screen and (max-width: 576px) {
  .m-xs-bottom-140 {
    margin-bottom: 140px !important;
  }
}
.m-bottom-130 {
  margin-bottom: 130px !important;
}
@media screen and (min-width: 992px) {
  .m-lg-bottom-130 {
    margin-bottom: 130px !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .m-md-bottom-130 {
    margin-bottom: 130px !important;
  }
}
@media screen and (max-width: 576px) {
  .m-xs-bottom-130 {
    margin-bottom: 130px !important;
  }
}
.m-bottom-120 {
  margin-bottom: 120px !important;
}
@media screen and (min-width: 992px) {
  .m-lg-bottom-120 {
    margin-bottom: 120px !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .m-md-bottom-120 {
    margin-bottom: 120px !important;
  }
}
@media screen and (max-width: 576px) {
  .m-xs-bottom-120 {
    margin-bottom: 120px !important;
  }
}
.m-bottom-110 {
  margin-bottom: 110px !important;
}
@media screen and (min-width: 992px) {
  .m-lg-bottom-110 {
    margin-bottom: 110px !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .m-md-bottom-110 {
    margin-bottom: 110px !important;
  }
}
@media screen and (max-width: 576px) {
  .m-xs-bottom-110 {
    margin-bottom: 110px !important;
  }
}
.m-bottom-100 {
  margin-bottom: 100px !important;
}
@media screen and (min-width: 992px) {
  .m-lg-bottom-100 {
    margin-bottom: 100px !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .m-md-bottom-100 {
    margin-bottom: 100px !important;
  }
}
@media screen and (max-width: 576px) {
  .m-xs-bottom-100 {
    margin-bottom: 100px !important;
  }
}
.m-bottom-90 {
  margin-bottom: 90px !important;
}
@media screen and (min-width: 992px) {
  .m-lg-bottom-90 {
    margin-bottom: 90px !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .m-md-bottom-90 {
    margin-bottom: 90px !important;
  }
}
@media screen and (max-width: 576px) {
  .m-xs-bottom-90 {
    margin-bottom: 90px !important;
  }
}
.m-bottom-80 {
  margin-bottom: 80px !important;
}
@media screen and (min-width: 992px) {
  .m-lg-bottom-80 {
    margin-bottom: 80px !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .m-md-bottom-80 {
    margin-bottom: 80px !important;
  }
}
@media screen and (max-width: 576px) {
  .m-xs-bottom-80 {
    margin-bottom: 80px !important;
  }
}
.m-bottom-70 {
  margin-bottom: 70px !important;
}
@media screen and (min-width: 992px) {
  .m-lg-bottom-70 {
    margin-bottom: 70px !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .m-md-bottom-70 {
    margin-bottom: 70px !important;
  }
}
@media screen and (max-width: 576px) {
  .m-xs-bottom-70 {
    margin-bottom: 70px !important;
  }
}
.m-bottom-60 {
  margin-bottom: 60px !important;
}
@media screen and (min-width: 992px) {
  .m-lg-bottom-60 {
    margin-bottom: 60px !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .m-md-bottom-60 {
    margin-bottom: 60px !important;
  }
}
@media screen and (max-width: 576px) {
  .m-xs-bottom-60 {
    margin-bottom: 60px !important;
  }
}
.m-bottom-50 {
  margin-bottom: 50px !important;
}
@media screen and (min-width: 992px) {
  .m-lg-bottom-50 {
    margin-bottom: 50px !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .m-md-bottom-50 {
    margin-bottom: 50px !important;
  }
}
@media screen and (max-width: 576px) {
  .m-xs-bottom-50 {
    margin-bottom: 50px !important;
  }
}
.m-bottom-40 {
  margin-bottom: 40px !important;
}
@media screen and (min-width: 992px) {
  .m-lg-bottom-40 {
    margin-bottom: 40px !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .m-md-bottom-40 {
    margin-bottom: 40px !important;
  }
}
@media screen and (max-width: 576px) {
  .m-xs-bottom-40 {
    margin-bottom: 40px !important;
  }
}
.m-bottom-30 {
  margin-bottom: 30px !important;
}
@media screen and (min-width: 992px) {
  .m-lg-bottom-30 {
    margin-bottom: 30px !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .m-md-bottom-30 {
    margin-bottom: 30px !important;
  }
}
@media screen and (max-width: 576px) {
  .m-xs-bottom-30 {
    margin-bottom: 30px !important;
  }
}
.m-bottom-20 {
  margin-bottom: 20px !important;
}
@media screen and (min-width: 992px) {
  .m-lg-bottom-20 {
    margin-bottom: 20px !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .m-md-bottom-20 {
    margin-bottom: 20px !important;
  }
}
@media screen and (max-width: 576px) {
  .m-xs-bottom-20 {
    margin-bottom: 20px !important;
  }
}
.m-bottom-10 {
  margin-bottom: 10px !important;
}
@media screen and (min-width: 992px) {
  .m-lg-bottom-10 {
    margin-bottom: 10px !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .m-md-bottom-10 {
    margin-bottom: 10px !important;
  }
}
@media screen and (max-width: 576px) {
  .m-xs-bottom-10 {
    margin-bottom: 10px !important;
  }
}
.m-left-200 {
  margin-left: 200px !important;
}
@media screen and (min-width: 992px) {
  .m-lg-left-200 {
    margin-left: 200px !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .m-md-left-200 {
    margin-left: 200px !important;
  }
}
@media screen and (max-width: 576px) {
  .m-xs-left-200 {
    margin-left: 200px !important;
  }
}
.m-left-190 {
  margin-left: 190px !important;
}
@media screen and (min-width: 992px) {
  .m-lg-left-190 {
    margin-left: 190px !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .m-md-left-190 {
    margin-left: 190px !important;
  }
}
@media screen and (max-width: 576px) {
  .m-xs-left-190 {
    margin-left: 190px !important;
  }
}
.m-left-180 {
  margin-left: 180px !important;
}
@media screen and (min-width: 992px) {
  .m-lg-left-180 {
    margin-left: 180px !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .m-md-left-180 {
    margin-left: 180px !important;
  }
}
@media screen and (max-width: 576px) {
  .m-xs-left-180 {
    margin-left: 180px !important;
  }
}
.m-left-170 {
  margin-left: 170px !important;
}
@media screen and (min-width: 992px) {
  .m-lg-left-170 {
    margin-left: 170px !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .m-md-left-170 {
    margin-left: 170px !important;
  }
}
@media screen and (max-width: 576px) {
  .m-xs-left-170 {
    margin-left: 170px !important;
  }
}
.m-left-160 {
  margin-left: 160px !important;
}
@media screen and (min-width: 992px) {
  .m-lg-left-160 {
    margin-left: 160px !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .m-md-left-160 {
    margin-left: 160px !important;
  }
}
@media screen and (max-width: 576px) {
  .m-xs-left-160 {
    margin-left: 160px !important;
  }
}
.m-left-150 {
  margin-left: 150px !important;
}
@media screen and (min-width: 992px) {
  .m-lg-left-150 {
    margin-left: 150px !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .m-md-left-150 {
    margin-left: 150px !important;
  }
}
@media screen and (max-width: 576px) {
  .m-xs-left-150 {
    margin-left: 150px !important;
  }
}
.m-left-140 {
  margin-left: 140px !important;
}
@media screen and (min-width: 992px) {
  .m-lg-left-140 {
    margin-left: 140px !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .m-md-left-140 {
    margin-left: 140px !important;
  }
}
@media screen and (max-width: 576px) {
  .m-xs-left-140 {
    margin-left: 140px !important;
  }
}
.m-left-130 {
  margin-left: 130px !important;
}
@media screen and (min-width: 992px) {
  .m-lg-left-130 {
    margin-left: 130px !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .m-md-left-130 {
    margin-left: 130px !important;
  }
}
@media screen and (max-width: 576px) {
  .m-xs-left-130 {
    margin-left: 130px !important;
  }
}
.m-left-120 {
  margin-left: 120px !important;
}
@media screen and (min-width: 992px) {
  .m-lg-left-120 {
    margin-left: 120px !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .m-md-left-120 {
    margin-left: 120px !important;
  }
}
@media screen and (max-width: 576px) {
  .m-xs-left-120 {
    margin-left: 120px !important;
  }
}
.m-left-110 {
  margin-left: 110px !important;
}
@media screen and (min-width: 992px) {
  .m-lg-left-110 {
    margin-left: 110px !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .m-md-left-110 {
    margin-left: 110px !important;
  }
}
@media screen and (max-width: 576px) {
  .m-xs-left-110 {
    margin-left: 110px !important;
  }
}
.m-left-100 {
  margin-left: 100px !important;
}
@media screen and (min-width: 992px) {
  .m-lg-left-100 {
    margin-left: 100px !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .m-md-left-100 {
    margin-left: 100px !important;
  }
}
@media screen and (max-width: 576px) {
  .m-xs-left-100 {
    margin-left: 100px !important;
  }
}
.m-left-90 {
  margin-left: 90px !important;
}
@media screen and (min-width: 992px) {
  .m-lg-left-90 {
    margin-left: 90px !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .m-md-left-90 {
    margin-left: 90px !important;
  }
}
@media screen and (max-width: 576px) {
  .m-xs-left-90 {
    margin-left: 90px !important;
  }
}
.m-left-80 {
  margin-left: 80px !important;
}
@media screen and (min-width: 992px) {
  .m-lg-left-80 {
    margin-left: 80px !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .m-md-left-80 {
    margin-left: 80px !important;
  }
}
@media screen and (max-width: 576px) {
  .m-xs-left-80 {
    margin-left: 80px !important;
  }
}
.m-left-70 {
  margin-left: 70px !important;
}
@media screen and (min-width: 992px) {
  .m-lg-left-70 {
    margin-left: 70px !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .m-md-left-70 {
    margin-left: 70px !important;
  }
}
@media screen and (max-width: 576px) {
  .m-xs-left-70 {
    margin-left: 70px !important;
  }
}
.m-left-60 {
  margin-left: 60px !important;
}
@media screen and (min-width: 992px) {
  .m-lg-left-60 {
    margin-left: 60px !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .m-md-left-60 {
    margin-left: 60px !important;
  }
}
@media screen and (max-width: 576px) {
  .m-xs-left-60 {
    margin-left: 60px !important;
  }
}
.m-left-50 {
  margin-left: 50px !important;
}
@media screen and (min-width: 992px) {
  .m-lg-left-50 {
    margin-left: 50px !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .m-md-left-50 {
    margin-left: 50px !important;
  }
}
@media screen and (max-width: 576px) {
  .m-xs-left-50 {
    margin-left: 50px !important;
  }
}
.m-left-40 {
  margin-left: 40px !important;
}
@media screen and (min-width: 992px) {
  .m-lg-left-40 {
    margin-left: 40px !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .m-md-left-40 {
    margin-left: 40px !important;
  }
}
@media screen and (max-width: 576px) {
  .m-xs-left-40 {
    margin-left: 40px !important;
  }
}
.m-left-30 {
  margin-left: 30px !important;
}
@media screen and (min-width: 992px) {
  .m-lg-left-30 {
    margin-left: 30px !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .m-md-left-30 {
    margin-left: 30px !important;
  }
}
@media screen and (max-width: 576px) {
  .m-xs-left-30 {
    margin-left: 30px !important;
  }
}
.m-left-20 {
  margin-left: 20px !important;
}
@media screen and (min-width: 992px) {
  .m-lg-left-20 {
    margin-left: 20px !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .m-md-left-20 {
    margin-left: 20px !important;
  }
}
@media screen and (max-width: 576px) {
  .m-xs-left-20 {
    margin-left: 20px !important;
  }
}
.m-left-10 {
  margin-left: 10px !important;
}
@media screen and (min-width: 992px) {
  .m-lg-left-10 {
    margin-left: 10px !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .m-md-left-10 {
    margin-left: 10px !important;
  }
}
@media screen and (max-width: 576px) {
  .m-xs-left-10 {
    margin-left: 10px !important;
  }
}
.m-right-200 {
  margin-right: 200px !important;
}
@media screen and (min-width: 992px) {
  .m-lg-right-200 {
    margin-right: 200px !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .m-md-right-200 {
    margin-right: 200px !important;
  }
}
@media screen and (max-width: 576px) {
  .m-xs-right-200 {
    margin-right: 200px !important;
  }
}
.m-right-190 {
  margin-right: 190px !important;
}
@media screen and (min-width: 992px) {
  .m-lg-right-190 {
    margin-right: 190px !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .m-md-right-190 {
    margin-right: 190px !important;
  }
}
@media screen and (max-width: 576px) {
  .m-xs-right-190 {
    margin-right: 190px !important;
  }
}
.m-right-180 {
  margin-right: 180px !important;
}
@media screen and (min-width: 992px) {
  .m-lg-right-180 {
    margin-right: 180px !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .m-md-right-180 {
    margin-right: 180px !important;
  }
}
@media screen and (max-width: 576px) {
  .m-xs-right-180 {
    margin-right: 180px !important;
  }
}
.m-right-170 {
  margin-right: 170px !important;
}
@media screen and (min-width: 992px) {
  .m-lg-right-170 {
    margin-right: 170px !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .m-md-right-170 {
    margin-right: 170px !important;
  }
}
@media screen and (max-width: 576px) {
  .m-xs-right-170 {
    margin-right: 170px !important;
  }
}
.m-right-160 {
  margin-right: 160px !important;
}
@media screen and (min-width: 992px) {
  .m-lg-right-160 {
    margin-right: 160px !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .m-md-right-160 {
    margin-right: 160px !important;
  }
}
@media screen and (max-width: 576px) {
  .m-xs-right-160 {
    margin-right: 160px !important;
  }
}
.m-right-150 {
  margin-right: 150px !important;
}
@media screen and (min-width: 992px) {
  .m-lg-right-150 {
    margin-right: 150px !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .m-md-right-150 {
    margin-right: 150px !important;
  }
}
@media screen and (max-width: 576px) {
  .m-xs-right-150 {
    margin-right: 150px !important;
  }
}
.m-right-140 {
  margin-right: 140px !important;
}
@media screen and (min-width: 992px) {
  .m-lg-right-140 {
    margin-right: 140px !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .m-md-right-140 {
    margin-right: 140px !important;
  }
}
@media screen and (max-width: 576px) {
  .m-xs-right-140 {
    margin-right: 140px !important;
  }
}
.m-right-130 {
  margin-right: 130px !important;
}
@media screen and (min-width: 992px) {
  .m-lg-right-130 {
    margin-right: 130px !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .m-md-right-130 {
    margin-right: 130px !important;
  }
}
@media screen and (max-width: 576px) {
  .m-xs-right-130 {
    margin-right: 130px !important;
  }
}
.m-right-120 {
  margin-right: 120px !important;
}
@media screen and (min-width: 992px) {
  .m-lg-right-120 {
    margin-right: 120px !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .m-md-right-120 {
    margin-right: 120px !important;
  }
}
@media screen and (max-width: 576px) {
  .m-xs-right-120 {
    margin-right: 120px !important;
  }
}
.m-right-110 {
  margin-right: 110px !important;
}
@media screen and (min-width: 992px) {
  .m-lg-right-110 {
    margin-right: 110px !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .m-md-right-110 {
    margin-right: 110px !important;
  }
}
@media screen and (max-width: 576px) {
  .m-xs-right-110 {
    margin-right: 110px !important;
  }
}
.m-right-100 {
  margin-right: 100px !important;
}
@media screen and (min-width: 992px) {
  .m-lg-right-100 {
    margin-right: 100px !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .m-md-right-100 {
    margin-right: 100px !important;
  }
}
@media screen and (max-width: 576px) {
  .m-xs-right-100 {
    margin-right: 100px !important;
  }
}
.m-right-90 {
  margin-right: 90px !important;
}
@media screen and (min-width: 992px) {
  .m-lg-right-90 {
    margin-right: 90px !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .m-md-right-90 {
    margin-right: 90px !important;
  }
}
@media screen and (max-width: 576px) {
  .m-xs-right-90 {
    margin-right: 90px !important;
  }
}
.m-right-80 {
  margin-right: 80px !important;
}
@media screen and (min-width: 992px) {
  .m-lg-right-80 {
    margin-right: 80px !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .m-md-right-80 {
    margin-right: 80px !important;
  }
}
@media screen and (max-width: 576px) {
  .m-xs-right-80 {
    margin-right: 80px !important;
  }
}
.m-right-70 {
  margin-right: 70px !important;
}
@media screen and (min-width: 992px) {
  .m-lg-right-70 {
    margin-right: 70px !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .m-md-right-70 {
    margin-right: 70px !important;
  }
}
@media screen and (max-width: 576px) {
  .m-xs-right-70 {
    margin-right: 70px !important;
  }
}
.m-right-60 {
  margin-right: 60px !important;
}
@media screen and (min-width: 992px) {
  .m-lg-right-60 {
    margin-right: 60px !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .m-md-right-60 {
    margin-right: 60px !important;
  }
}
@media screen and (max-width: 576px) {
  .m-xs-right-60 {
    margin-right: 60px !important;
  }
}
.m-right-50 {
  margin-right: 50px !important;
}
@media screen and (min-width: 992px) {
  .m-lg-right-50 {
    margin-right: 50px !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .m-md-right-50 {
    margin-right: 50px !important;
  }
}
@media screen and (max-width: 576px) {
  .m-xs-right-50 {
    margin-right: 50px !important;
  }
}
.m-right-40 {
  margin-right: 40px !important;
}
@media screen and (min-width: 992px) {
  .m-lg-right-40 {
    margin-right: 40px !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .m-md-right-40 {
    margin-right: 40px !important;
  }
}
@media screen and (max-width: 576px) {
  .m-xs-right-40 {
    margin-right: 40px !important;
  }
}
.m-right-30 {
  margin-right: 30px !important;
}
@media screen and (min-width: 992px) {
  .m-lg-right-30 {
    margin-right: 30px !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .m-md-right-30 {
    margin-right: 30px !important;
  }
}
@media screen and (max-width: 576px) {
  .m-xs-right-30 {
    margin-right: 30px !important;
  }
}
.m-right-20 {
  margin-right: 20px !important;
}
@media screen and (min-width: 992px) {
  .m-lg-right-20 {
    margin-right: 20px !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .m-md-right-20 {
    margin-right: 20px !important;
  }
}
@media screen and (max-width: 576px) {
  .m-xs-right-20 {
    margin-right: 20px !important;
  }
}
.m-right-10 {
  margin-right: 10px !important;
}
@media screen and (min-width: 992px) {
  .m-lg-right-10 {
    margin-right: 10px !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .m-md-right-10 {
    margin-right: 10px !important;
  }
}
@media screen and (max-width: 576px) {
  .m-xs-right-10 {
    margin-right: 10px !important;
  }
}
.m-top-auto {
  margin-top: auto;
}
@media screen and (min-width: 992px) {
  .m-lg-top-auto {
    margin-top: auto;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .m-md-top-auto {
    margin-top: auto;
  }
}
@media screen and (max-width: 576px) {
  .m-xs-top-auto {
    margin-top: auto;
  }
}
.m-bottom-auto {
  margin-bottom: auto;
}
@media screen and (min-width: 992px) {
  .m-lg-bottom-auto {
    margin-bottom: auto;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .m-md-bottom-auto {
    margin-bottom: auto;
  }
}
@media screen and (max-width: 576px) {
  .m-xs-bottom-auto {
    margin-bottom: auto;
  }
}
.m-left-auto {
  margin-left: auto;
}
@media screen and (min-width: 992px) {
  .m-lg-left-auto {
    margin-left: auto;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .m-md-left-auto {
    margin-left: auto;
  }
}
@media screen and (max-width: 576px) {
  .m-xs-left-auto {
    margin-left: auto;
  }
}
.m-right-auto {
  margin-right: auto;
}
@media screen and (min-width: 992px) {
  .m-lg-right-auto {
    margin-right: auto;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .m-md-right-auto {
    margin-right: auto;
  }
}
@media screen and (max-width: 576px) {
  .m-xs-right-auto {
    margin-right: auto;
  }
}
@media screen and (min-width: 992px) {
  .m-lg-auto {
    margin: auto;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .m-md-auto {
    margin: auto;
  }
}
@media screen and (max-width: 576px) {
  .m-xs-auto {
    margin: auto;
  }
}
.link {
  transition: all 0.1s linear;
}
.link-common {
  color: #3d3d46;
  font-size: 12px;
  line-height: 14px;
}
.link-primary {
  text-align: center;
  background: #ea5353;
  border-radius: 8px;
  color: white !important;
  min-height: 36px;
  line-height: 16px;
  padding: 10px 18.5px;
}
.link-primary:hover {
  color: white;
  background: #c74545;
}
.link-primary:active,
.link-primary:focus {
  color: white;
  background: #983131;
}
.link-big {
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: #3d3d46;
  text-decoration: underline;
}
.link-big:hover,
.link-big:active,
.link-big:focus {
  text-decoration: underline;
  color: #3d3d46;
}
.link-underlined {
  color: #3d3d46;
  font-size: 14px;
  line-height: 17px;
  text-decoration: underline;
}
.link-underlined:hover,
.link-underlined:active,
.link-underlined:focus {
  text-decoration: underline;
  color: #3d3d46;
}
.flex {
  display: flex !important;
}
.flex-j-center {
  justify-content: center !important;
}
.flex-j-end {
  justify-content: flex-end !important;
}
.flex-j-space-between {
  justify-content: space-between !important;
}
.flex-align-center {
  align-items: center !important;
}
.flex-grow-1 {
  flex-grow: 1 !important;
}
@media screen and (min-width: 992px) {
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
}
@media screen and (max-width: 576px) {
  .flex-xs-grow-1 {
    flex-grow: 1 !important;
  }
}
.on-hover-cursor-pointer:hover {
  cursor: pointer;
}
.animated {
  transition: all 0.1s linear;
}
.section-title {
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 20px;
}
@media screen and (max-width: 575px) {
  .w-xs-100p {
    width: 100%;
  }
}
.m-auto {
  margin: 0 auto !important;
}
.m-top-5 {
  margin-top: 5px !important;
}
@font-face {
  font-family: "Bw Modelica";
  src: url("fonts/BwModelica-ExtraBoldItalic.eot");
  src: url("fonts/BwModelica-ExtraBoldItalic.eot?#iefix") format("embedded-opentype"), url("fonts/BwModelica-ExtraBoldItalic.woff2") format("woff2"), url("fonts/BwModelica-ExtraBoldItalic.woff") format("woff"), url("fonts/BwModelica-ExtraBoldItalic.ttf") format("truetype"), url("fonts/BwModelica-ExtraBoldItalic.svg#BwModelica-ExtraBoldItalic") format("svg");
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: "Bw Modelica";
  src: url("fonts/BwModelica-Bold.eot");
  src: url("fonts/BwModelica-Bold.eot?#iefix") format("embedded-opentype"), url("fonts/BwModelica-Bold.woff2") format("woff2"), url("fonts/BwModelica-Bold.woff") format("woff"), url("fonts/BwModelica-Bold.ttf") format("truetype"), url("fonts/BwModelica-Bold.svg#BwModelica-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Bw Modelica";
  src: url("fonts/BwModelica-RegularItalic.eot");
  src: url("fonts/BwModelica-RegularItalic.eot?#iefix") format("embedded-opentype"), url("fonts/BwModelica-RegularItalic.woff2") format("woff2"), url("fonts/BwModelica-RegularItalic.woff") format("woff"), url("fonts/BwModelica-RegularItalic.ttf") format("truetype"), url("fonts/BwModelica-RegularItalic.svg#BwModelica-RegularItalic") format("svg");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "Bw Modelica";
  src: url("fonts/BwModelica-LightItalic.eot");
  src: url("fonts/BwModelica-LightItalic.eot?#iefix") format("embedded-opentype"), url("fonts/BwModelica-LightItalic.woff2") format("woff2"), url("fonts/BwModelica-LightItalic.woff") format("woff"), url("fonts/BwModelica-LightItalic.ttf") format("truetype"), url("fonts/BwModelica-LightItalic.svg#BwModelica-LightItalic") format("svg");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "Bw Modelica";
  src: url("fonts/BwModelica-ThinItalic.eot");
  src: url("fonts/BwModelica-ThinItalic.eot?#iefix") format("embedded-opentype"), url("fonts/BwModelica-ThinItalic.woff2") format("woff2"), url("fonts/BwModelica-ThinItalic.woff") format("woff"), url("fonts/BwModelica-ThinItalic.ttf") format("truetype"), url("fonts/BwModelica-ThinItalic.svg#BwModelica-ThinItalic") format("svg");
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: "Bw Modelica";
  src: url("fonts/BwModelica-BlackItalic.eot");
  src: url("fonts/BwModelica-BlackItalic.eot?#iefix") format("embedded-opentype"), url("fonts/BwModelica-BlackItalic.woff2") format("woff2"), url("fonts/BwModelica-BlackItalic.woff") format("woff"), url("fonts/BwModelica-BlackItalic.ttf") format("truetype"), url("fonts/BwModelica-BlackItalic.svg#BwModelica-BlackItalic") format("svg");
  font-weight: 900;
  font-style: italic;
}
@font-face {
  font-family: "Bw Modelica Hairline";
  src: url("fonts/BwModelica-Hairline.eot");
  src: url("fonts/BwModelica-Hairline.eot?#iefix") format("embedded-opentype"), url("fonts/BwModelica-Hairline.woff2") format("woff2"), url("fonts/BwModelica-Hairline.woff") format("woff"), url("fonts/BwModelica-Hairline.ttf") format("truetype"), url("fonts/BwModelica-Hairline.svg#BwModelica-Hairline") format("svg");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "Bw Modelica";
  src: url("fonts/BwModelica-MediumItalic.eot");
  src: url("fonts/BwModelica-MediumItalic.eot?#iefix") format("embedded-opentype"), url("fonts/BwModelica-MediumItalic.woff2") format("woff2"), url("fonts/BwModelica-MediumItalic.woff") format("woff"), url("fonts/BwModelica-MediumItalic.ttf") format("truetype"), url("fonts/BwModelica-MediumItalic.svg#BwModelica-MediumItalic") format("svg");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "Bw Modelica";
  src: url("fonts/BwModelica-Black.eot");
  src: url("fonts/BwModelica-Black.eot?#iefix") format("embedded-opentype"), url("fonts/BwModelica-Black.woff2") format("woff2"), url("fonts/BwModelica-Black.woff") format("woff"), url("fonts/BwModelica-Black.ttf") format("truetype"), url("fonts/BwModelica-Black.svg#BwModelica-Black") format("svg");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Bw Modelica";
  src: url("fonts/BwModelica-ExtraBold.eot");
  src: url("fonts/BwModelica-ExtraBold.eot?#iefix") format("embedded-opentype"), url("fonts/BwModelica-ExtraBold.woff2") format("woff2"), url("fonts/BwModelica-ExtraBold.woff") format("woff"), url("fonts/BwModelica-ExtraBold.ttf") format("truetype"), url("fonts/BwModelica-ExtraBold.svg#BwModelica-ExtraBold") format("svg");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "Bw Modelica";
  src: url("fonts/BwModelica-Regular.eot");
  src: url("fonts/BwModelica-Regular.eot?#iefix") format("embedded-opentype"), url("fonts/BwModelica-Regular.woff2") format("woff2"), url("fonts/BwModelica-Regular.woff") format("woff"), url("fonts/BwModelica-Regular.ttf") format("truetype"), url("fonts/BwModelica-Regular.svg#BwModelica-Regular") format("svg");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Bw Modelica Hairline";
  src: url("fonts/BwModelica-HairlineItalic.eot");
  src: url("fonts/BwModelica-HairlineItalic.eot?#iefix") format("embedded-opentype"), url("fonts/BwModelica-HairlineItalic.woff2") format("woff2"), url("fonts/BwModelica-HairlineItalic.woff") format("woff"), url("fonts/BwModelica-HairlineItalic.ttf") format("truetype"), url("fonts/BwModelica-HairlineItalic.svg#BwModelica-HairlineItalic") format("svg");
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: "Bw Modelica";
  src: url("fonts/BwModelica-Light.eot");
  src: url("fonts/BwModelica-Light.eot?#iefix") format("embedded-opentype"), url("fonts/BwModelica-Light.woff2") format("woff2"), url("fonts/BwModelica-Light.woff") format("woff"), url("fonts/BwModelica-Light.ttf") format("truetype"), url("fonts/BwModelica-Light.svg#BwModelica-Light") format("svg");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Bw Modelica";
  src: url("fonts/BwModelica-Thin.eot");
  src: url("fonts/BwModelica-Thin.eot?#iefix") format("embedded-opentype"), url("fonts/BwModelica-Thin.woff2") format("woff2"), url("fonts/BwModelica-Thin.woff") format("woff"), url("fonts/BwModelica-Thin.ttf") format("truetype"), url("fonts/BwModelica-Thin.svg#BwModelica-Thin") format("svg");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "Bw Modelica";
  src: url("fonts/BwModelica-Medium.eot");
  src: url("fonts/BwModelica-Medium.eot?#iefix") format("embedded-opentype"), url("fonts/BwModelica-Medium.woff2") format("woff2"), url("fonts/BwModelica-Medium.woff") format("woff"), url("fonts/BwModelica-Medium.ttf") format("truetype"), url("fonts/BwModelica-Medium.svg#BwModelica-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Bw Modelica";
  src: url("fonts/BwModelica-BoldItalic.eot");
  src: url("fonts/BwModelica-BoldItalic.eot?#iefix") format("embedded-opentype"), url("fonts/BwModelica-BoldItalic.woff2") format("woff2"), url("fonts/BwModelica-BoldItalic.woff") format("woff"), url("fonts/BwModelica-BoldItalic.ttf") format("truetype"), url("fonts/BwModelica-BoldItalic.svg#BwModelica-BoldItalic") format("svg");
  font-weight: bold;
  font-style: italic;
}
.sign-up-container {
  max-width: 1200px;
  margin: 0 auto;
}
@media screen and (min-width: 577px) and (max-width: 991px) and (orientation: portrait) {
  .sign-up-container .account-information-section {
    flex-direction: column;
    align-items: center !important;
  }
}
.success-container .ant-card-body {
  padding: 50px 0 40px;
}
.success-container .ant-card-body .auth-container .title {
  margin-top: 24px;
}
@media screen and (max-width: 575px) {
  .auth-container form {
    padding: 0;
  }
}
.auth-container .single-column {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.auth-container .single-column .ant-form-item,
.auth-container .single-column .button-container {
  width: 100%;
}
@media screen and (max-width: 575px) {
  .auth-container .single-column {
    max-width: initial;
    align-items: flex-start;
  }
}
.auth-container .resend-verification-link {
  margin-top: 5px;
  margin-bottom: 70px;
}
.auth-container .resend-verification-link button span {
  color: #3d3d46;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  text-decoration-line: underline;
}
.auth-container ul {
  text-align: left;
  padding-left: 0;
}
@media screen and (max-width: 575px) {
  .ant-radio-group.ant-radio-group-solid {
    width: 100%;
  }
}
.ant-radio-group.ant-radio-group-solid .ant-radio-button-wrapper {
  width: 33.3%;
  text-align: center;
  font-size: 12px;
  color: #837ae9;
}
.ant-radio-group.ant-radio-group-solid .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
  font-weight: bold;
  color: #fff;
}
.ant-radio-group.ant-radio-group-solid .ant-radio-button-wrapper.ant-radio-button-wrapper-disabled {
  border-color: #cdd1d9;
  color: #cdd1d9;
}
.ant-radio-group.ant-radio-group-solid .ant-radio-button-wrapper.ant-radio-button-wrapper-disabled:before {
  background-color: #cdd1d9;
}
@media screen and (max-width: 575px) {
  .ant-radio-group.ant-radio-group-solid .ant-radio-button-wrapper {
    padding: 0;
  }
}
@media (min-width: 577px) {
  .ant-radio-group.ant-radio-group-solid .ant-radio-button-wrapper {
    min-width: 130px;
  }
}
.bordered.ant-radio-group.ant-radio-group-outline .ant-radio-wrapper {
  padding: 12px 17px;
}
.bordered.ant-radio-group.ant-radio-group-outline .ant-radio-wrapper.ant-radio-wrapper-checked {
  border: 1px solid #837ae9;
  color: #837ae9;
  border-radius: 63px;
  padding: 12px 17px;
  font-weight: bold;
}
.ant-radio-group.ant-radio-group-outline .ant-radio-wrapper .ant-radio input[type="radio"] {
  width: 16px;
  height: 16px;
}
.ant-radio-group.ant-radio-group-outline .ant-radio-wrapper .ant-radio .ant-radio-inner:after {
  top: -1px;
  left: -1px;
  width: 16px;
  height: 16px;
  color: #fff;
  background-image: url("../icons/check.svg");
  background-repeat: no-repeat;
  background-position-x: 3px;
  background-position-y: 4px;
}
.ant-steps-horizontal {
  margin-bottom: -18px;
  justify-content: center;
}
.ant-steps-horizontal .ant-steps-item-icon {
  font-family: "Bw Modelica" !important;
  width: 34px;
  height: 34px;
  margin-right: 10px;
}
.ant-steps-horizontal .ant-steps-item-title {
  font-size: 12px;
  line-height: 130%;
}
.ant-steps-horizontal .ant-steps-item-title:after {
  top: 50%;
}
.ant-steps-horizontal .ant-steps-item.ant-steps-item-process {
  font-family: "Bw Modelica" !important;
  font-weight: 500;
}
.ant-steps-horizontal .ant-steps-item-content {
  width: 114px;
  white-space: normal;
  line-height: 40px;
}
.ant-steps-label-vertical .ant-steps-item-content {
  text-align: left;
  width: 108px;
}
@media screen and (max-width: 575px) {
  .ant-steps-label-vertical .ant-steps-item-content {
    width: 70px;
  }
}
@media screen and (max-width: 575px) {
  .ant-steps-label-vertical .ant-steps-item-content .ant-steps-item-title {
    font-size: 9px;
    line-height: 11px;
  }
}
.ant-steps-label-vertical .ant-steps-item-icon {
  margin-left: 0;
}
@media screen and (max-width: 575px) {
  .ant-steps-label-vertical .ant-steps-item-icon {
    width: 28px;
    height: 28px;
  }
}
.ant-steps-label-vertical .ant-steps-item-icon .ant-steps-icon {
  font-family: "Bw Modelica" !important;
}
@media screen and (max-width: 575px) {
  .ant-steps-label-vertical .ant-steps-item-icon .ant-steps-icon {
    font-size: 14px;
    line-height: 17px;
    top: -4px;
  }
}
@media screen and (max-width: 575px) {
  .ant-steps-label-vertical .ant-steps-item-tail {
    margin-left: 18px;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px) {
  .ant-steps-label-vertical .ant-steps-item-tail {
    margin-left: 30px;
    width: calc(100% - 24px);
  }
}
.auth-container .title {
  margin-top: 50px;
  margin-bottom: 20px;
}
.auth-container .title h1 {
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
}
label.ant-checkbox-wrapper {
  font-size: 14px !important;
  line-height: 17px !important;
  display: flex;
}
label.ant-checkbox-wrapper a {
  color: #3d3d46;
  text-decoration: underline;
  font-weight: 500;
}
.ant-steps-item-wait > .ant-steps-item-content > .ant-steps-item-title {
  color: #3d3d46;
}
.add-phone-link {
  display: block;
  margin-top: -14px;
}
.ant-steps-item-finish .ant-steps-item-title {
  color: #837ae9 !important;
}
.ant-steps-item-finish .ant-steps-icon .anticon {
  display: none;
}
.ant-steps-item-finish .ant-steps-icon:after {
  font-family: "Bw Modelica";
}
.step-5.ant-steps-item-finish .ant-steps-icon:after {
  content: "5";
}
.step-4.ant-steps-item-finish .ant-steps-icon:after {
  content: "4";
}
.step-3.ant-steps-item-finish .ant-steps-icon:after {
  content: "3";
}
.step-2.ant-steps-item-finish .ant-steps-icon:after {
  content: "2";
}
.step-1.ant-steps-item-finish .ant-steps-icon:after {
  content: "1";
}
.ant-steps-item-icon > .ant-steps-icon {
  top: 0;
}
.ant-input-lg,
.ant-select-lg,
.ant-input-number-lg,
.ant-input-number-input {
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;
}
.ant-input[disabled],
.ant-select-disabled {
  color: #696b6d;
}
.ant-form label {
  font-size: 12px;
  line-height: 14px;
}
.ant-checkbox-inner {
  border-radius: 4px;
}
.ant-checkbox-inner:after {
  left: 25%;
}
.ant-checkbox-wrapper-disabled .ant-checkbox-inner {
  border-color: #cdd1d9 !important;
}
.ant-form-item {
  margin-bottom: 20px;
}
.ant-form-item.no-margin {
  margin-bottom: 0;
}
.ant-form-item.ant-form-item-disabled .ant-form-item-label label {
  color: #a2a7b0;
}
.ant-form-vertical .ant-form-item-label {
  line-height: 1;
}
.ant-form-item-control .ant-input-suffix {
  right: 14px;
}
.ant-form-item-control.has-feedback .ant-input-suffix {
  right: 44px;
}
.ant-input-number-lg {
  width: 100% !important;
  border: 1px solid #cdd1d9;
}
.ant-form-extra {
  color: #3d3d46;
  padding-top: 11px;
}
.ant-form-explain,
.ant-form-extra {
  margin-top: 5px;
  font-size: 12px;
  line-height: 13px;
  margin-bottom: 0;
  min-height: auto;
  text-align: left;
}
.ant-select-dropdown {
  z-index: 1000001;
}
.ant-form-item-label {
  line-height: 16px;
  text-align: left;
  margin-bottom: 8px;
}
.ant-radio-group.ant-radio-group-outline .ant-radio-wrapper .ant-radio .ant-radio-inner:after {
  width: 18px;
  height: 18px;
  background-repeat: no-repeat;
  background-position-x: 4px;
  background-position-y: 5px;
  border-radius: 50%;
}
.ant-radio-inner,
form input[type="checkbox"],
.ant-checkbox-inner {
  width: 18px;
  height: 18px;
}
.ant-radio-disabled .ant-radio-inner {
  border-color: #cdd1d9 !important;
}
.ant-radio-disabled .ant-radio-inner:after {
  background-color: #837ae9;
}
.ant-radio-disabled + span {
  color: #3d3d46;
}
.ant-radio-wrapper {
  font-size: 14px !important;
  line-height: 17px !important;
  font-weight: 500;
}
.ant-checkbox + span {
  padding-right: 0;
}
.ant-checkbox-inner {
  width: 18px;
  height: 18px;
}
.ant-checkbox-inner:after {
  left: 26%;
}
.ant-select-selection-selected-value {
  font-weight: 500;
}
.ant-select-selection .ant-select-clear-icon {
  font-size: 14px;
}
.ant-radio-button-wrapper {
  border-color: #a8a2f0;
}
.ant-radio-button-wrapper:first-child {
  border-left-color: #a8a2f0;
}
.no-wrap-error-message .ant-form-explain {
  white-space: nowrap;
}
.ant-calendar-picker-clear {
  margin-right: 22px;
}
.ant-calendar-picker.prefix-to div {
  position: relative;
}
.ant-calendar-picker.prefix-to div:before {
  content: "To";
  display: block;
  font-size: 12px;
  line-height: 14px;
  width: 30px;
  height: 100%;
  position: absolute;
  z-index: 2;
  top: calc(50% - 6px);
  left: 12px;
}
.ant-calendar-picker.prefix-to div input {
  padding-left: 40px;
}
.ant-calendar-picker.prefix-from div {
  position: relative;
}
.ant-calendar-picker.prefix-from div:before {
  content: "From";
  display: block;
  font-size: 12px;
  line-height: 14px;
  width: 30px;
  height: 100%;
  position: absolute;
  z-index: 2;
  top: calc(50% - 6px);
  left: 12px;
}
.ant-calendar-picker.prefix-from div input {
  padding-left: 51px;
}
.ant-btn-primary {
  font-weight: bold;
}
.ant-btn-secondary {
  border: 1px solid #837ae9;
  color: #837ae9;
  font-weight: 500;
}
.ant-btn-secondary:hover,
.ant-btn-secondary:focus {
  background-color: #f1f0ff;
  border: 1px solid #837ae9;
  color: #837ae9;
}
.ant-btn-secondary:active {
  background-color: #f1f0ff;
  border: 1px solid #5e55c2;
  color: #5e55c2;
}
.ant-btn-ghost {
  border: 1px solid #ea5353;
  color: #ea5353;
  font-weight: 500;
}
.ant-btn-ghost:hover,
.ant-btn-ghost:focus {
  background-color: #fff;
  border: 1px solid #c74545;
  color: #c74545;
}
.ant-btn-ghost:active {
  border: 1px solid #983131;
  color: #983131;
}
button[disabled].ant-btn-ghost {
  background-color: #fafafa !important;
  border: 1px solid #a2a7b0 !important;
  color: #a2a7b0 !important;
}
.action-button {
  padding: 0 16px;
  display: flex;
  align-items: center;
  line-height: 0;
  border: 1px solid #837ae9;
  background-color: #837ae9;
  color: #fff;
}
.action-button .anticon {
  margin-right: 10px;
}
.action-button:hover,
.action-button:focus,
.action-button:active {
  border: 1px solid #5e55c2;
  background-color: #5e55c2;
  color: #fff;
}
.ant-card-body {
  padding: 30px 0;
}
.authorization-loader {
  position: absolute;
  top: 50%;
  left: 50%;
}
.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 100;
}
.ant-page-header {
  padding-left: 0 !important;
  padding-bottom: 30px !important;
}
.ant-page-header .ant-page-header-title-view-title {
  font-weight: bold;
  font-size: 42px;
  line-height: 120%;
}
.ant-page-header .ant-page-header-title-view-extra {
  display: flex;
}
.ant-page-header .ant-page-header-title-view-extra > * {
  margin-left: 10px;
  margin-right: 10px;
}
@media screen and (max-width: 768px) {
  .ant-page-header .ant-page-header-title-view-extra > * {
    margin-left: 0;
    margin-right: 0;
    margin-top: 10px;
  }
}
@media screen and (max-width: 768px) {
  .ant-page-header .ant-page-header-title-view-extra {
    position: relative;
    flex-direction: column;
  }
}
@media screen and (max-width: 991px) {
  .ant-page-header {
    padding-bottom: 30px !important;
  }
}
.ant-message {
  z-index: 10000000;
}
* {
  font-feature-settings: normal !important;
}
.ant-table {
  font-variant: common-ligatures !important;
}
.ant-select-dropdown-menu .ant-spin-spinning {
  width: 100%;
  text-align: center;
}
.ant-divider.ant-divider-horizontal {
  margin: 40px 0;
}
.ant-divider.ant-divider-horizontal.small-margin {
  margin: 20px 0;
}
.ant-divider.ant-divider-horizontal.medium-margin {
  margin: 30px 0;
}
.ant-modal-mask,
.ant-modal-wrap {
  z-index: 1000001 !important;
}
.ant-modal .ant-modal-content,
.ant-modal-confirm .ant-modal-content,
.ant-modal-confirm-info .ant-modal-content {
  border-radius: 6px;
}
.ant-modal .ant-modal-header,
.ant-modal-confirm .ant-modal-header,
.ant-modal-confirm-info .ant-modal-header,
.ant-modal .ant-modal-footer,
.ant-modal-confirm .ant-modal-footer,
.ant-modal-confirm-info .ant-modal-footer,
.ant-modal .ant-modal-body,
.ant-modal-confirm .ant-modal-body,
.ant-modal-confirm-info .ant-modal-body {
  padding-left: 50px !important;
  padding-right: 50px !important;
}
@media screen and (max-width: 576px) {
  .ant-modal .ant-modal-header,
  .ant-modal-confirm .ant-modal-header,
  .ant-modal-confirm-info .ant-modal-header,
  .ant-modal .ant-modal-footer,
  .ant-modal-confirm .ant-modal-footer,
  .ant-modal-confirm-info .ant-modal-footer,
  .ant-modal .ant-modal-body,
  .ant-modal-confirm .ant-modal-body,
  .ant-modal-confirm-info .ant-modal-body {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}
.ant-modal .ant-modal-body,
.ant-modal-confirm .ant-modal-body,
.ant-modal-confirm-info .ant-modal-body {
  padding-top: 10px;
  padding-bottom: 0;
}
.ant-modal .ant-modal-header,
.ant-modal-confirm .ant-modal-header,
.ant-modal-confirm-info .ant-modal-header {
  padding-top: 40px;
  border-bottom: none;
}
.ant-modal .ant-modal-header .ant-modal-title,
.ant-modal-confirm .ant-modal-header .ant-modal-title,
.ant-modal-confirm-info .ant-modal-header .ant-modal-title {
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  font-weight: bold;
}
.ant-modal .ant-modal-footer,
.ant-modal-confirm .ant-modal-footer,
.ant-modal-confirm-info .ant-modal-footer {
  border-top: none;
  padding-top: 10px;
  padding-bottom: 40px;
  display: flex;
}
.ant-modal .ant-modal-footer div,
.ant-modal-confirm .ant-modal-footer div,
.ant-modal-confirm-info .ant-modal-footer div {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.ant-modal .ant-modal-footer button + button,
.ant-modal-confirm .ant-modal-footer button + button,
.ant-modal-confirm-info .ant-modal-footer button + button {
  margin-left: 20px;
}
.ant-modal-confirm .ant-modal-confirm-btns,
.ant-modal-confirm-info .ant-modal-confirm-btns {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.ant-modal-confirm .ant-modal-confirm-btns button,
.ant-modal-confirm-info .ant-modal-confirm-btns button {
  width: 100%;
  flex-grow: 1;
}
.ant-modal-confirm-info .ant-modal-confirm-btns,
.ant-modal-confirm-confirm .ant-modal-confirm-btns {
  padding-bottom: 40px;
}
.ant-modal-confirm-title {
  font-weight: bold !important;
  font-size: 24px !important;
  text-align: center;
  padding-top: 30px;
}
.modal-no-title .ant-modal-confirm-title {
  padding-top: 0;
}
.ant-modal-confirm-body .ant-modal-confirm-content {
  margin-top: 30px;
}
.ant-pagination-disabled {
  color: #cdd1d9 !important;
}
.ant-pagination-prev {
  margin-right: 13px !important;
  color: #a2a7b0 !important;
}
.ant-pagination-prev:not(.ant-pagination-disabled):hover {
  color: #3d3d46 !important;
}
.ant-pagination-next {
  color: #a2a7b0 !important;
}
.ant-pagination-next:not(.ant-pagination-disabled):hover {
  color: #3d3d46 !important;
}
.ant-pagination-item a {
  color: #a2a7b0 !important;
}
.ant-pagination-item a:hover {
  color: #3d3d46 !important;
}
.ant-pagination-item {
  margin-right: 16px !important;
}
.ant-pagination-item-active {
  border-color: #3d3d46 !important;
}
.ant-pagination-item-active a {
  color: #3d3d46 !important;
}
.ant-table-body {
  overflow-x: auto !important;
}
@media all and (max-width: 1080px) {
  .ant-table-body {
    overflow-x: scroll !important;
  }
}
.ant-table-thead > tr > th,
.ant-table-selection-column {
  border-bottom-color: #fff !important;
}
.ant-table-thead > tr > th {
  font-weight: bold;
}
@media screen and (max-width: 575px) {
  .ant-table {
    overflow-x: scroll;
  }
}
.ant-table-wrapper.table-compact .ant-table {
  font-size: 12px !important;
}
.ant-table-wrapper.table-compact .ant-table td {
  padding: 16px 10px;
}
.ant-table-wrapper.only-single-selection .ant-table-selection {
  display: none;
}
.ant-table-column-title {
  font-weight: bold;
}
.table-expand-icon {
  transition: transform 0.3s ease-out;
}
.table-expand-icon-opened {
  transform: rotate(180deg);
}
tr.ant-table-expanded-row,
tr.ant-table-expanded-row:hover,
tr.ant-row-parent-expanded {
  background: #f6f7fb !important;
}
tr.ant-row-parent-expanded .ant-table-row-expand-icon-cell {
  border-bottom: none;
}
.ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
  background: #f6f7fb !important;
}
.ant-table-footer {
  border-top: 1px solid #fff !important;
  display: flex;
  background: transparent !important;
  padding-top: 50px !important;
  padding-right: 0 !important;
}
.ant-table-footer:before {
  background: #fff;
}
[data-sc-index] {
  margin-top: 20px;
  position: relative;
  border-bottom: 1px solid #cdd1d9;
}
[data-sc-index]:before {
  content: attr(data-sc-index);
  width: 35px;
  position: absolute;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  left: -35px;
  color: #3d3d46;
}
[data-sc-index]:last-of-type {
  border-bottom: none;
}
.ant-table-tbody > tr > td:nth-child(2),
.ant-table-thead > tr > th:nth-child(2) {
  padding-left: 0 !important;
}
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 16px 0 16px 16px !important;
}
.ant-table-thead > tr > th:last-of-type,
.ant-table-tbody > tr:not(.ant-table-expanded-row) > td:last-of-type {
  padding-right: 16px !important;
}
.ant-table-expanded-row > td:last-of-type {
  padding-right: 0 !important;
}
.ant-table-thead > tr > th:nth-child(1),
.ant-table-tbody > tr > td:nth-child(1) {
  padding-right: 16px !important;
}
.ant-calendar-picker-icon {
  transform: translate(0, -15%);
}
.date-range-picker .ant-calendar-picker-input {
  padding-left: 9px;
  padding-right: 9px;
}
.date-range-picker .ant-calendar-picker-input .ant-calendar-range-picker-input {
  width: 39%;
  text-align: left;
}
.date-range-picker .ant-calendar-picker-input .ant-calendar-range-picker-separator {
  position: relative;
  margin-left: 20px;
  margin-right: 13px;
  line-height: 2;
  vertical-align: middle;
}
.date-range-picker .ant-calendar-picker-input .ant-calendar-range-picker-separator:before {
  content: "";
  min-width: 19px;
  min-height: 19px;
  position: absolute;
  display: block;
  right: 26px;
  top: 4px;
  background-image: url("../icons/calendar-purple.svg");
}
.time-range-picker .ant-calendar-range-picker-separator:before {
  background-repeat: no-repeat;
  background-image: url("../icons/clock.svg") !important;
}
.ant-time-picker-large .ant-time-picker-input {
  font-size: 14px;
  line-height: 17px;
}
.time-range-picker-dropdown .ant-calendar-footer-extra {
  width: 100%;
  text-align: right;
}
.time-range-picker-dropdown .ant-calendar-time-picker-btn,
.time-range-picker-dropdown .ant-calendar-ok-btn {
  display: none !important;
}
.time-range-picker-dropdown .ant-calendar-range-middle {
  display: none;
}
.time-range-picker-dropdown .ant-calendar-header {
  display: none;
}
.time-range-picker-dropdown .ant-calendar-time-picker {
  top: 28px !important;
}
.ant-badge-dot {
  top: 3px;
  box-shadow: none !important;
  right: 2px;
  z-index: auto;
  width: 6px;
  height: 6px;
  background: #f5222d;
  border-radius: 100%;
}
.embedded-description-column {
  padding: 16px 0 16px 16px;
  vertical-align: top;
}
[data-sc-index] .embedded-description-column {
  padding: 16px 0 16px 16px;
}
.embedded-description-column:nth-child(1) {
  padding-left: 0;
}
.embedded-description-label {
  font-size: 11px;
  line-height: 13px;
  font-weight: bold;
  margin-bottom: 6px;
}
.embedded-description:first-of-type .embedded-description-column {
  padding-top: 0;
}
.ant-table-expanded-row > td:nth-child(2) {
  padding: 0;
}
.ant-tooltip {
  z-index: 10000001;
}
.ant-tooltip-arrow {
  display: none;
}
.ant-tooltip-inner {
  box-shadow: 0 3px 12px rgba(151, 151, 151, 0.5);
  background: #696b6d;
  font-size: 14px;
  line-height: 128%;
  padding: 9px 10px;
  font-weight: 500;
}
.ant-tooltip-inner ul,
.ant-tooltip-inner ol {
  padding-inline-start: 16px;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-result {
  padding: 48px 32px;
}
.ant-result-success .ant-result-icon > .anticon {
  color: #52c41a;
}
.ant-result-error .ant-result-icon > .anticon {
  color: #f5222d;
}
.ant-result-info .ant-result-icon > .anticon {
  color: #1890ff;
}
.ant-result-warning .ant-result-icon > .anticon {
  color: #faad14;
}
.ant-result-image {
  width: 250px;
  height: 295px;
  margin: auto;
}
.ant-result-icon {
  margin-bottom: 24px;
  text-align: center;
}
.ant-result-icon > .anticon {
  font-size: 72px;
}
.ant-result-title {
  color: #3d3d46;
  font-size: 24px;
  line-height: 1.8;
  text-align: center;
}
.ant-result-subtitle {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.6;
  text-align: center;
}
.ant-result-extra {
  margin-top: 32px;
  text-align: center;
}
.ant-result-extra > * {
  margin-right: 8px;
}
.ant-result-extra > *:last-child {
  margin-right: 0;
}
.ant-result-content {
  margin-top: 24px;
  padding: 24px 40px;
  background-color: #fafafa;
}

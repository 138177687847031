@import "../../styles/ant-theme";
@import "../../styles/responsive";
.contact-us-form {
  .method-of-communication {
    padding-bottom: 10px;
  }
  .send-button {
    min-width: 160px;
  }
  .button-wrapper {
    flex-direction: row;
    justify-content: flex-end;
  }
  .phone-wrapper,
  .email-wrapper,
  .support-wrapper {
    position: relative;
    padding-top: 70px;
    display: flex;
    flex-direction: column;
  }
  .phone-number,
  .email-link,
  .support-email {
    padding-top: 8px;
  }
  .buttons-wrapper {
    .media-tablet({display: flex; justify-content: space-between;});
    .media-phone({display: flex; justify-content: space-between;});
  }
  .truck-icon {
    &:before {
      content: "";
      position: absolute;
      display: block;
      background-image: url("../../icons/truck2.svg");
      background-repeat: no-repeat;
      width: 48px;
      height: 33px;
      left: calc(-48px - 22px);
    }
  }
  .phone-icon {
    &:before {
      content: "";
      position: absolute;
      display: block;
      background-image: url("../../icons/phone.svg");
      background-repeat: no-repeat;
      width: 25px;
      height: 38px;
      left: calc(-50px + 5px);
      top: 75px;
    }
  }
  .email-icon {
    &:before {
      content: "";
      position: absolute;
      display: block;
      background-image: url("../../icons/email.svg");
      background-repeat: no-repeat;
      width: 36px;
      height: 25px;
      left: calc(-63px + 5px);
      top: 80px;
    }
  }
  .contact-us-icon {
    &:before {
      content: "";
      position: absolute;
      display: block;
      background-image: url("../../icons/support.svg");
      background-repeat: no-repeat;
      width: 36px;
      height: 36px;
      left: calc(-63px + 5px);
      top: 80px;
    }
  }
  .content-wrapper {
    .media-tablet({margin-top: 30px; margin-bottom: 30px;});
    .media-phone({margin-top: 30px; margin-bottom: 30px;});
  }
}

@primary-color: #837ae9;@text-color: #3d3d46;@text-gray: #a2a7b0;@heading-color: @text-color;@base-disabled-color: #f0f0f0;@base-disabled-text-color: #696b6d;@disabled-bg: @base-disabled-color;@gray: #cdd1d9;@font-size-lg: 16px;@border-color-base: @text-gray;@controls-border: @gray;@border-color-split: @controls-border;@card-radius: 6px;@card-shadow: 0px 10px 20px rgba(65, 39, 70, 0.1);@card-padding-base: 30px;@btn-height-base: 30px;@btn-padding-base: 0 10px;@btn-primary-bg: #ea5353;@btn-primary-bg-hover: #c74545;@btn-primary-bg-active: #983131;@btn-disable-border: @base-disabled-color;@btn-padding-lg: 0 32px;@btn-height-lg: 42px;@btn-default-color: @border-color-base;@input-border-color: @controls-border;@select-border-color: @controls-border;@input-height-lg: 42px;@input-disabled-bg: #fafafa;@page-header-padding-horizontal: 0;@zindex-picker: 1000001;@table-header-bg: #f6f7fb;@table-row-hover-bg: #f6f7fb;@badge-dot-size: 10px;@status-padding-top-bottom: 5px;@status-padding-left-right: 10px;@checkbox-padding-top: 8px;@popup-menu-top-bottom: 10px;@popup-menu-left-right: 21px;@pending-color: #a2a7b0;@radio-wrapper-border-color: #a8a2f0;@switch-checked-bg: #69E283;@switch-unchecked-bg: @gray;
@font-face {
  font-family: "Bw Modelica";
  src: url("BwModelica-ExtraBoldItalic.eot");
  src: url("BwModelica-ExtraBoldItalic.eot?#iefix") format("embedded-opentype"),
    url("BwModelica-ExtraBoldItalic.woff2") format("woff2"),
    url("BwModelica-ExtraBoldItalic.woff") format("woff"),
    url("BwModelica-ExtraBoldItalic.ttf") format("truetype"),
    url("BwModelica-ExtraBoldItalic.svg#BwModelica-ExtraBoldItalic")
      format("svg");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "Bw Modelica";
  src: url("BwModelica-Bold.eot");
  src: url("BwModelica-Bold.eot?#iefix") format("embedded-opentype"),
    url("BwModelica-Bold.woff2") format("woff2"),
    url("BwModelica-Bold.woff") format("woff"),
    url("BwModelica-Bold.ttf") format("truetype"),
    url("BwModelica-Bold.svg#BwModelica-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Bw Modelica";
  src: url("BwModelica-RegularItalic.eot");
  src: url("BwModelica-RegularItalic.eot?#iefix") format("embedded-opentype"),
    url("BwModelica-RegularItalic.woff2") format("woff2"),
    url("BwModelica-RegularItalic.woff") format("woff"),
    url("BwModelica-RegularItalic.ttf") format("truetype"),
    url("BwModelica-RegularItalic.svg#BwModelica-RegularItalic") format("svg");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Bw Modelica";
  src: url("BwModelica-LightItalic.eot");
  src: url("BwModelica-LightItalic.eot?#iefix") format("embedded-opentype"),
    url("BwModelica-LightItalic.woff2") format("woff2"),
    url("BwModelica-LightItalic.woff") format("woff"),
    url("BwModelica-LightItalic.ttf") format("truetype"),
    url("BwModelica-LightItalic.svg#BwModelica-LightItalic") format("svg");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Bw Modelica";
  src: url("BwModelica-ThinItalic.eot");
  src: url("BwModelica-ThinItalic.eot?#iefix") format("embedded-opentype"),
    url("BwModelica-ThinItalic.woff2") format("woff2"),
    url("BwModelica-ThinItalic.woff") format("woff"),
    url("BwModelica-ThinItalic.ttf") format("truetype"),
    url("BwModelica-ThinItalic.svg#BwModelica-ThinItalic") format("svg");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "Bw Modelica";
  src: url("BwModelica-BlackItalic.eot");
  src: url("BwModelica-BlackItalic.eot?#iefix") format("embedded-opentype"),
    url("BwModelica-BlackItalic.woff2") format("woff2"),
    url("BwModelica-BlackItalic.woff") format("woff"),
    url("BwModelica-BlackItalic.ttf") format("truetype"),
    url("BwModelica-BlackItalic.svg#BwModelica-BlackItalic") format("svg");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Bw Modelica Hairline";
  src: url("BwModelica-Hairline.eot");
  src: url("BwModelica-Hairline.eot?#iefix") format("embedded-opentype"),
    url("BwModelica-Hairline.woff2") format("woff2"),
    url("BwModelica-Hairline.woff") format("woff"),
    url("BwModelica-Hairline.ttf") format("truetype"),
    url("BwModelica-Hairline.svg#BwModelica-Hairline") format("svg");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Bw Modelica";
  src: url("BwModelica-MediumItalic.eot");
  src: url("BwModelica-MediumItalic.eot?#iefix") format("embedded-opentype"),
    url("BwModelica-MediumItalic.woff2") format("woff2"),
    url("BwModelica-MediumItalic.woff") format("woff"),
    url("BwModelica-MediumItalic.ttf") format("truetype"),
    url("BwModelica-MediumItalic.svg#BwModelica-MediumItalic") format("svg");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Bw Modelica";
  src: url("BwModelica-Black.eot");
  src: url("BwModelica-Black.eot?#iefix") format("embedded-opentype"),
    url("BwModelica-Black.woff2") format("woff2"),
    url("BwModelica-Black.woff") format("woff"),
    url("BwModelica-Black.ttf") format("truetype"),
    url("BwModelica-Black.svg#BwModelica-Black") format("svg");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Bw Modelica";
  src: url("BwModelica-ExtraBold.eot");
  src: url("BwModelica-ExtraBold.eot?#iefix") format("embedded-opentype"),
    url("BwModelica-ExtraBold.woff2") format("woff2"),
    url("BwModelica-ExtraBold.woff") format("woff"),
    url("BwModelica-ExtraBold.ttf") format("truetype"),
    url("BwModelica-ExtraBold.svg#BwModelica-ExtraBold") format("svg");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Bw Modelica";
  src: url("BwModelica-Regular.eot");
  src: url("BwModelica-Regular.eot?#iefix") format("embedded-opentype"),
    url("BwModelica-Regular.woff2") format("woff2"),
    url("BwModelica-Regular.woff") format("woff"),
    url("BwModelica-Regular.ttf") format("truetype"),
    url("BwModelica-Regular.svg#BwModelica-Regular") format("svg");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Bw Modelica Hairline";
  src: url("BwModelica-HairlineItalic.eot");
  src: url("BwModelica-HairlineItalic.eot?#iefix") format("embedded-opentype"),
    url("BwModelica-HairlineItalic.woff2") format("woff2"),
    url("BwModelica-HairlineItalic.woff") format("woff"),
    url("BwModelica-HairlineItalic.ttf") format("truetype"),
    url("BwModelica-HairlineItalic.svg#BwModelica-HairlineItalic") format("svg");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "Bw Modelica";
  src: url("BwModelica-Light.eot");
  src: url("BwModelica-Light.eot?#iefix") format("embedded-opentype"),
    url("BwModelica-Light.woff2") format("woff2"),
    url("BwModelica-Light.woff") format("woff"),
    url("BwModelica-Light.ttf") format("truetype"),
    url("BwModelica-Light.svg#BwModelica-Light") format("svg");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Bw Modelica";
  src: url("BwModelica-Thin.eot");
  src: url("BwModelica-Thin.eot?#iefix") format("embedded-opentype"),
    url("BwModelica-Thin.woff2") format("woff2"),
    url("BwModelica-Thin.woff") format("woff"),
    url("BwModelica-Thin.ttf") format("truetype"),
    url("BwModelica-Thin.svg#BwModelica-Thin") format("svg");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Bw Modelica";
  src: url("BwModelica-Medium.eot");
  src: url("BwModelica-Medium.eot?#iefix") format("embedded-opentype"),
    url("BwModelica-Medium.woff2") format("woff2"),
    url("BwModelica-Medium.woff") format("woff"),
    url("BwModelica-Medium.ttf") format("truetype"),
    url("BwModelica-Medium.svg#BwModelica-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Bw Modelica";
  src: url("BwModelica-BoldItalic.eot");
  src: url("BwModelica-BoldItalic.eot?#iefix") format("embedded-opentype"),
    url("BwModelica-BoldItalic.woff2") format("woff2"),
    url("BwModelica-BoldItalic.woff") format("woff"),
    url("BwModelica-BoldItalic.ttf") format("truetype"),
    url("BwModelica-BoldItalic.svg#BwModelica-BoldItalic") format("svg");
  font-weight: bold;
  font-style: italic;
}

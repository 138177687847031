.ant-modal-mask,
.ant-modal-wrap {
  z-index: 1000001 !important;
}

.ant-modal,
.ant-modal-confirm,
.ant-modal-confirm-info {
  .ant-modal-content {
    border-radius: 6px;
  }
  .ant-modal-header,
  .ant-modal-footer,
  .ant-modal-body {
    padding-left: 50px !important;
    padding-right: 50px !important;
    @media screen and (max-width: @screen-phone) {
      padding-left: 20px !important;
      padding-right: 20px !important;
    }
  }
  .ant-modal-body {
    padding-top: 10px;
    padding-bottom: 0;
  }
  .ant-modal-header {
    padding-top: 40px;
    border-bottom: none;
    .ant-modal-title {
      font-size: 24px;
      line-height: 29px;
      text-align: center;
      font-weight: bold;
    }
  }
  .ant-modal-footer {
    border-top: none;
    padding-top: 10px;
    padding-bottom: 40px;
    display: flex;
    div {
      display: flex;
      justify-content: flex-end;
      width: 100%;
    }
    button + button {
      margin-left: 20px;
    }
  }
}
.ant-modal-confirm,
.ant-modal-confirm-info {
  .ant-modal-confirm-btns {
    width: 100%;
    display: flex;
    justify-content: space-between;
    button {
      width: 100%;
      flex-grow: 1;
    }
  }
}
.ant-modal-confirm-info,
.ant-modal-confirm-confirm {
  .ant-modal-confirm-btns {
    padding-bottom: 40px;
  }
}

.ant-modal-confirm-title {
  font-weight: bold !important;
  font-size: 24px !important;
  text-align: center;
  padding-top: 30px;
  .modal-no-title & {
    padding-top: 0;
  }
}
.ant-modal-confirm-body .ant-modal-confirm-content {
  margin-top: 30px;
}

@import "../../styles/ant-theme";
@import "../../styles/responsive";

@play-btn-size: 120px;

.ant-layout .ant-layout-content.info-layout {
  padding-top: 0;
}

.static-page-hero-img {
  background-position-x: center;
  height: 445px;
  position: relative;
  width: 100vw;
  margin-left: calc(((100vw - 100%) / 2) * -1);
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: -100px;
  .media-phone({height: calc(100vw / 2) ; background-position: inherit;});
}

.static-page-img {
  width: 80%;

  &-with-shadow {
    box-shadow: 0 6px 25px rgba(0, 0, 0, 0.15);
  }

  .media-tablet({width: 100%});
  .media-phone({width: 100%});
}

.shipper-img,
.agent-img,
.truck-owner-img {
  background-position-x: center;
  height: 445px;
  position: relative;
  width: 100vw;
  margin-left: calc(((100vw - 100%) / 2) * -1);
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: -100px;
  .media-phone({background-position-x: 60%;});
}

.shipper-img {
  background-image: url("../../styles/images/shipper-bg.png");
}
.agent-img {
  background-image: url("../../styles/images/agent-bg.png");
  .media-tablet({background-position-x: 58%;});
  .media-phone({background-position-x: 70%;});
}
.truck-owner-img {
  background-image: url("../../styles/images/truck-owner-bg.png");
  .media-tablet({background-position-x: 5%;});
  .media-phone({background-position-x: 20%;});
}

h1.page-header {
  margin-bottom: 2.5em;
}

.info-content {
  line-height: 1.5rem;
  font-weight: 400;
}
li.steps {
  list-style-type: none !important;
  margin-left: 0px !important;
  padding-left: 0px !important;
}
li.decimals {
  list-style-type: decimal !important;
}

.video-what-we-do {
  margin-bottom: 20px;
  background-repeat: no-repeat;
  border-radius: 4px;
  position: relative;

  img,
  picture {
    object-fit: cover;
    border-radius: 5px;
    width: 100%;
    .media-phone({min-height: 250px; height: 100%;});
  }
  .media-phone(
    {min-height: 250px; .overlay {top: calc(50% - (@play-btn-size - 20px) / 2) ;
      left: calc(50% - (@play-btn-size - 20px) / 2) ; button {height: @play-btn-size -
      20px; width: @play-btn-size - 20px;}}}
  );
  .media-desktop({margin-bottom: 20px;});
}
.media-tablet(
  {padding: 25px 35px 40px; background-size: cover; background-position-x: 50%;}
);
.media-phone({padding: 40px 15px 80px; background-size: cover;});

.bottom-text {
  margin-bottom: 1.875em !important;
}
.btn-sign-up {
  font-size: 20px;
  font-weight: bold;
  line-height: 24px;
  padding: 10px 70px;
  .media-phone({display: block; line-height: 24px;});
  .media-tablet({padding: 10px 20px; font-weight: 500; ;});
}
@media screen and (max-width: 320px) {
  .page-header {
    font-size: 36px !important;
  }

  .img-wrapper-shipper {
    background-image: url("../../styles/images/shipper-example--xs-small.jpg");
    background-size: 320px;
    background-position-x: -19px;
    height: 380px;
  }
  .img-wrapper-agent {
    background-size: 320px;
    background-position-x: -19px;
    height: 371px;
  }
  .img-wrapper-truck-owner {
    background-size: 308px;
    background-position-x: -19px;
    height: 330px;
  }
}

.static-video-list {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  &-item {
    width: 280px;
    margin-right: 20px;

    .media-phone({width: 100%; margin-right: 0;});

    position: relative;
    img {
      width: 100%;
    }

    &-caption {
      margin-top: 20px;
      width: 100%;
      text-align: center;
      font-weight: bolder;
    }
  }
}

@primary-color: #837ae9;@text-color: #3d3d46;@text-gray: #a2a7b0;@heading-color: @text-color;@base-disabled-color: #f0f0f0;@base-disabled-text-color: #696b6d;@disabled-bg: @base-disabled-color;@gray: #cdd1d9;@font-size-lg: 16px;@border-color-base: @text-gray;@controls-border: @gray;@border-color-split: @controls-border;@card-radius: 6px;@card-shadow: 0px 10px 20px rgba(65, 39, 70, 0.1);@card-padding-base: 30px;@btn-height-base: 30px;@btn-padding-base: 0 10px;@btn-primary-bg: #ea5353;@btn-primary-bg-hover: #c74545;@btn-primary-bg-active: #983131;@btn-disable-border: @base-disabled-color;@btn-padding-lg: 0 32px;@btn-height-lg: 42px;@btn-default-color: @border-color-base;@input-border-color: @controls-border;@select-border-color: @controls-border;@input-height-lg: 42px;@input-disabled-bg: #fafafa;@page-header-padding-horizontal: 0;@zindex-picker: 1000001;@table-header-bg: #f6f7fb;@table-row-hover-bg: #f6f7fb;@badge-dot-size: 10px;@status-padding-top-bottom: 5px;@status-padding-left-right: 10px;@checkbox-padding-top: 8px;@popup-menu-top-bottom: 10px;@popup-menu-left-right: 21px;@pending-color: #a2a7b0;@radio-wrapper-border-color: #a8a2f0;@switch-checked-bg: #69E283;@switch-unchecked-bg: @gray;
.ant-tooltip {
  z-index: 10000001;

  &-arrow {
    display: none;
  }

  &-inner {
    box-shadow: 0 3px 12px rgba(151, 151, 151, 0.5);
    background: #696b6d;
    font-size: 14px;
    line-height: 128%;
    padding: 9px 10px;
    font-weight: 500;

    ul,
    ol {
      padding-inline-start: 16px;
    }
  }
}

.cb-grid-lines {
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  pointer-events: none;
  background: rgba(99, 99, 99, 0.07);
  z-index: 16777271;
  margin: 0;
}
.grid-overlay-container {
  pointer-events: none;
  box-sizing: border-box;
  height: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: row;
}
.grid-overlay-horizontal {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: fixed;
  pointer-events: none;
  z-index: 16777271;
  margin: 0;
}
.grid-overlay-col {
  height: 100%;
  pointer-events: none;
  opacity: 0.25;
  margin: 0 8px;
  display: inline-block;
}
.grid-overlay-row {
  pointer-events: none;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
}
.cb-grid-lines {
  width: 100%;
}
.grid-overlay-container {
  max-width: 1280px;
  padding: 0px 40px;
  left: 0px;
}
.grid-overlay-col {
  width: 4.16666667%;
  margin: 0 5px;
  background: rgba(155, 0, 65, 0.2);
}
.grid-overlay-col:first-child {
  margin-left: 0px;
}
.grid-overlay-col:last-child {
  margin-right: 0px;
}
.grid-overlay-horizontal {
  background-image: linear-gradient(to top, rgba(155, 0, 65, 0.2) 1px, transparent 1px);
  background-size: 100% 16px;
  background-repeat-y: repeat;
  background-position-y: 0px;
}
.cb-grid-lines {
  width: 100%;
}
.grid-overlay-container {
  max-width: 1280px;
  padding: 0px 40px;
  left: 0px;
}
.grid-overlay-col {
  width: 4.16666667%;
  margin: 0 5px;
  background: rgba(155, 0, 65, 0.2);
}
.grid-overlay-col:first-child {
  margin-left: 0px;
}
.grid-overlay-col:last-child {
  margin-right: 0px;
}
.grid-overlay-horizontal {
  background-image: linear-gradient(to top, rgba(155, 0, 65, 0.2) 1px, transparent 1px);
  background-size: 100% 16px;
  background-repeat-y: repeat;
  background-position-y: 0px;
}
@media screen and (max-width: 767px) {
  .grid-overlay-col {
    width: 16.66666667%;
    margin: 0 5px;
    background: rgba(155, 0, 65, 0.2);
  }
  .grid-overlay-container {
    padding: 0px 15px;
    left: 0px;
  }
  .grid-overlay-col:first-child {
    margin-left: 0px;
  }
  .grid-overlay-col:nth-child(6) {
    margin-right: 0px;
  }
  .grid-overlay-col:nth-child(n + 7) {
    display: none;
  }
}
@media screen and (min-width: 768px) and (max-width: 1199px) {
  .grid-overlay-col {
    width: 8.33333333%;
    margin: 0 5px;
    background: rgba(155, 0, 65, 0.2);
  }
  .grid-overlay-container {
    padding: 0px 35px;
    left: 0px;
  }
  .grid-overlay-col:first-child {
    margin-left: 0px;
  }
  .grid-overlay-col:nth-child(12) {
    margin-right: 0px;
  }
  .grid-overlay-col:nth-child(n + 13) {
    display: none;
  }
}

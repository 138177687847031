@import "../../styles/ant-theme";
@import "../../styles/responsive";

.ant-layout-header {
  position: relative;
  min-height: 90px;
  background-color: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  padding: 0;
  font-size: 16px;
  font-family: "Bw Modelica";
  font-weight: 500;
  z-index: 1000001;

  border-bottom: 1px solid #cdd1d9;

  @media all and (max-width: 1200px) {
    padding-right: 40px;
    padding-left: 40px;
  }

  .media-desktop({border-bottom: none});

  .brand-logo {
    min-width: 153px;
    min-height: 42px;
    display: block;
    background-image: url("../../styles/images/logo.svg");
    background-repeat: no-repeat;
    background-size: 100%;
  }
  .logout-link {
    font-size: 14px;
    line-height: 17px;
    position: absolute;
    right: 15px;
    color: @text-color;
    text-decoration: underline;
  }

  .menu-trigger {
    position: absolute;
    left: 15px;
    top: calc(50% - 13px);
    .media-tablet({left: 35px});
  }
}
.ant-drawer.mobile-menu {
  transition: opacity 0.2s linear;
  opacity: 0;
  &.ant-drawer-open {
    opacity: 1;
  }
  &.ant-drawer-top {
    transform: translateY(90px);
    .ant-drawer-content-wrapper {
      height: auto !important;
    }
    .ant-drawer-body {
      padding-left: 31px;
      padding-top: 0;

      height: calc(100vh - 90px);
      overflow: scroll;

      .ant-menu-inline {
        border-right: none;
        .ant-menu-item {
          margin: 0;
          width: auto;
          display: table;
        }
        .ant-menu-item,
        .ant-menu-submenu-title {
          padding-left: 4px !important;
          font-weight: bold;
          font-size: 16px;
          line-height: 19px;
        }
        .ant-menu-item-selected {
          background-color: transparent;
          border-bottom: 3px solid @primary-color;
          &:after {
            content: none;
          }
        }
        .ant-menu-submenu-inline {
          .ant-menu-item {
            margin-left: 15px !important;
          }
        }
        // .ant-menu-submenu-selected {
        //    background-color: #f4f0ff;
        // }
      }
      .ant-menu-submenu-inline
        > .ant-menu-submenu-title
        .ant-menu-submenu-arrow {
        top: 10px;
      }
    }
  }
}
.ant-menu-horizontal {
  font-size: 16px;
  font-family: "Bw Modelica";
  font-weight: 500;
  line-height: 87px;
  border: none;
  > .ant-menu-item:hover,
  > .ant-menu-submenu:hover,
  > .ant-menu-item-active,
  > .ant-menu-submenu-active,
  > .ant-menu-item-open,
  > .ant-menu-submenu-open,
  > .ant-menu-item-selected,
  > .ant-menu-submenu-selected {
    border-bottom-width: 3px;
  }
}

.ant-menu-horizontal > .ant-menu-item,
.ant-menu-horizontal > .ant-menu-submenu {
  border-bottom: 3px solid transparent;
  &.ant-menu-item-selected {
    border-bottom-color: @primary-color;
  }
}

.ant-dropdown {
  z-index: 10000000;
}

.ant-menu-item a {
  color: #3d3d46;
}

@primary-color: #837ae9;@text-color: #3d3d46;@text-gray: #a2a7b0;@heading-color: @text-color;@base-disabled-color: #f0f0f0;@base-disabled-text-color: #696b6d;@disabled-bg: @base-disabled-color;@gray: #cdd1d9;@font-size-lg: 16px;@border-color-base: @text-gray;@controls-border: @gray;@border-color-split: @controls-border;@card-radius: 6px;@card-shadow: 0px 10px 20px rgba(65, 39, 70, 0.1);@card-padding-base: 30px;@btn-height-base: 30px;@btn-padding-base: 0 10px;@btn-primary-bg: #ea5353;@btn-primary-bg-hover: #c74545;@btn-primary-bg-active: #983131;@btn-disable-border: @base-disabled-color;@btn-padding-lg: 0 32px;@btn-height-lg: 42px;@btn-default-color: @border-color-base;@input-border-color: @controls-border;@select-border-color: @controls-border;@input-height-lg: 42px;@input-disabled-bg: #fafafa;@page-header-padding-horizontal: 0;@zindex-picker: 1000001;@table-header-bg: #f6f7fb;@table-row-hover-bg: #f6f7fb;@badge-dot-size: 10px;@status-padding-top-bottom: 5px;@status-padding-left-right: 10px;@checkbox-padding-top: 8px;@popup-menu-top-bottom: 10px;@popup-menu-left-right: 21px;@pending-color: #a2a7b0;@radio-wrapper-border-color: #a8a2f0;@switch-checked-bg: #69E283;@switch-unchecked-bg: @gray;
.ant-divider {
  &.ant-divider-horizontal {
    margin: 40px 0;
    &.small-margin {
      margin: 20px 0;
    }
    &.medium-margin {
      margin: 30px 0;
    }
  }
}

@import "../ant-theme";
@import "../responsive";

.sign-up-container {
  max-width: 1200px;
  margin: 0 auto;

  .account-information-section {
    @media screen and (min-width: (@screen-phone + 1px)) and (max-width: (@screen-desktop - 1px)) and (orientation: portrait) {
      flex-direction: column;
      align-items: center !important;
    }
  }
}

.success-container {
  .ant-card-body {
    padding: 50px 0 40px;
    .auth-container .title {
      margin-top: 24px;
    }
  }
}

.auth-container {
  form {
    //padding-left: 48px;
    //padding-right: 48px;
    .media-phone({padding: 0;});
  }

  .single-column {
    text-align: center;

    display: flex;
    flex-direction: column;
    align-items: center;

    .ant-form-item,
    .button-container {
      width: 100%;
    }

    .media-phone({max-width: initial; align-items: flex-start});
  }

  .resend-verification-link {
    margin-top: 5px;
    margin-bottom: 70px;
    button span {
      color: @text-color;
      font-size: 16px;
      line-height: 140%;
      text-align: center;
      text-decoration-line: underline;
    }
  }

  ul {
    text-align: left;
    padding-left: 0;
  }
}
.ant-radio-group {
  &.ant-radio-group-solid {
    .media-phone({width: 100%;});
    .ant-radio-button-wrapper {
      width: 33.3%;
      text-align: center;
      font-size: 12px;
      color: @primary-color;
      &.ant-radio-button-wrapper-checked {
        font-weight: bold;
        color: #fff;
      }

      &.ant-radio-button-wrapper-disabled {
        border-color: #cdd1d9;
        color: #cdd1d9;
        &:before {
          background-color: #cdd1d9;
        }
      }

      .media-phone({padding: 0});
      .media-gt-phone({min-width: 130px;});
    }
  }
  &.ant-radio-group-outline {
    .ant-radio-wrapper {
      .bordered& {
        padding: 12px 17px;
      }

      &.ant-radio-wrapper-checked {
        .bordered& {
          border: 1px solid @primary-color;
          color: @primary-color;
          border-radius: 63px;
          padding: 12px 17px;
          font-weight: bold;
        }
      }

      .ant-radio {
        input[type="radio"] {
          width: 16px;
          height: 16px;
        }
        .ant-radio-inner {
          &:after {
            top: -1px;
            left: -1px;
            width: 16px;
            height: 16px;
            color: #fff;
            background-image: url("../../icons/check.svg");
            background-repeat: no-repeat;
            background-position-x: 3px;
            background-position-y: 4px;
          }
        }
      }
    }
  }
}
.ant-steps-horizontal {
  margin-bottom: -18px;
  justify-content: center;
  .ant-steps-item-icon {
    font-family: "Bw Modelica" !important;
    width: 34px;
    height: 34px;
    margin-right: 10px;
  }
  .ant-steps-item-title {
    font-size: 12px;
    line-height: 130%;
    &:after {
      top: 50%;
    }
  }
  .ant-steps-item {
    &.ant-steps-item-process {
      font-family: "Bw Modelica" !important;
      font-weight: 500;
    }
  }
  .ant-steps-item-content {
    width: 114px;
    white-space: normal;
    line-height: 40px;
  }
}

.ant-steps-label-vertical {
  .ant-steps-item-content {
    text-align: left;
    width: 108px;
    .media-phone({width: 70px;});
    .ant-steps-item-title {
      .media-phone({font-size: 9px; line-height: 11px;});
    }
  }
  .ant-steps-item-icon {
    margin-left: 0;
    .media-phone({width: 28px; height: 28px;});
    .ant-steps-icon {
      font-family: "Bw Modelica" !important;
      .media-phone({font-size: 14px; line-height: 17px; top: -4px});
    }
  }
  .ant-steps-item-tail {
    .media-phone({margin-left: 18px;});
    .media-tablet({margin-left: 30px; width: calc(100% - 24px) ;});
  }
}

.auth-container {
  .title {
    margin-top: 50px;
    margin-bottom: 20px;
    h1 {
      font-weight: bold;
      font-size: 24px;
      line-height: 29px;
      //.media-phone({font-size: 24px; line-height: 26px;});
    }
  }
}

label.ant-checkbox-wrapper {
  font-size: 14px !important;
  line-height: 17px !important;
  display: flex;
  a {
    color: @text-color;
    text-decoration: underline;
    font-weight: 500;
  }
}

.ant-steps-item-wait > .ant-steps-item-content > .ant-steps-item-title {
  color: @text-color;
}

.add-phone-link {
  display: block;
  margin-top: -14px;
}
.ant-steps-item-finish {
  .ant-steps-item-title {
    color: @primary-color !important;
    //.media-phone({color: @primary-color;}) !important;
    //.media-tablet({color: @primary-color;}) !important;
  }
  .ant-steps-icon {
    .anticon {
      display: none;
    }
    &:after {
      font-family: "Bw Modelica";
    }
    .step-number(@size) when (@size > 0) {
      &:after {
        .step-@{size}& {
          content: "@{size}";
        }
      }
      .step-number(@size - 1);
    }

    .step-number(5);
  }
}
.ant-steps-item-icon > .ant-steps-icon {
  top: 0;
  //right: 1px;
}

@import "responsive";
@import "ant-theme";

.ant-input-lg,
.ant-select-lg,
.ant-input-number-lg,
.ant-input-number-input {
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;
  //.media-phone({font-size: 12px;});
}

.ant-input[disabled],
.ant-select-disabled {
  color: @base-disabled-text-color;
}

.ant-form label {
  font-size: 12px;
  line-height: 14px;
  //.media-phone({font-size: 10px; line-height: 12px;});
}
.ant-checkbox-inner {
  border-radius: 4px;
  &:after {
    left: 25%;
  }
}

.ant-checkbox-wrapper-disabled {
  .ant-checkbox-inner {
    border-color: #cdd1d9 !important;
  }
}

.ant-form-item {
  margin-bottom: 20px;

  &.no-margin {
    margin-bottom: 0;
  }

  &.ant-form-item-disabled {
    .ant-form-item-label {
      label {
        color: @text-gray;
      }
    }
  }
}
.ant-form-vertical .ant-form-item-label {
  line-height: 1;
}

.ant-form-item-control {
  .ant-input-suffix {
    right: 14px;
  }
  &.has-feedback {
    .ant-input-suffix {
      right: 44px;
    }
  }
}

.ant-input-number-lg {
  width: 100% !important;
  border: 1px solid @controls-border;
}

.ant-form-extra {
  color: @text-color;
  padding-top: 11px;
}

.ant-form-explain,
.ant-form-extra {
  margin-top: 5px;
  font-size: 12px;
  line-height: 13px;
  margin-bottom: 0;
  min-height: auto;
  text-align: left;
}

.ant-select-dropdown {
  z-index: 1000001;
}

.ant-form-item-label {
  line-height: 16px;
  text-align: left;
  margin-bottom: 8px;
  label {
    //white-space: pre-wrap;
  }
}

.ant-radio-group.ant-radio-group-outline
  .ant-radio-wrapper
  .ant-radio
  .ant-radio-inner:after {
  width: 18px;
  height: 18px;
  background-repeat: no-repeat;
  background-position-x: 4px;
  background-position-y: 5px;
  border-radius: 50%;
}
.ant-radio-inner,
form input[type="checkbox"],
.ant-checkbox-inner {
  width: 18px;
  height: 18px;
}
.ant-radio-disabled .ant-radio-inner {
  border-color: @gray !important;
  &:after {
    background-color: @primary-color;
  }
}
.ant-radio-disabled + span {
  color: @text-color;
}
.ant-radio-wrapper {
  font-size: 14px !important;
  line-height: 17px !important;
  font-weight: 500;
}
.ant-checkbox + span {
  padding-right: 0;
}
.ant-checkbox-inner {
  width: 18px;
  height: 18px;
  &:after {
    left: 26%;
  }
}

.ant-select-selection-selected-value {
  font-weight: 500;
}

.ant-select-selection {
  .ant-select-clear-icon {
    font-size: 14px;
  }
}

.ant-radio-button-wrapper {
  border-color: @radio-wrapper-border-color;
  &:first-child {
    border-left-color: @radio-wrapper-border-color;
  }
}

.no-wrap-error-message {
  .ant-form-explain {
    white-space: nowrap;
  }
}

.ant-calendar-picker {
  &-clear {
    margin-right: 22px;
  }
}

.ant-calendar-picker.prefix-to {
  div {
    position: relative;
    &:before {
      content: "To";
      display: block;
      font-size: 12px;
      line-height: 14px;
      width: 30px;
      height: 100%;
      position: absolute;
      z-index: 2;
      top: calc(50% - 6px);
      left: 12px;
    }
    input {
      padding-left: 40px;
    }
  }
}
.ant-calendar-picker.prefix-from {
  div {
    position: relative;
    &:before {
      content: "From";
      display: block;
      font-size: 12px;
      line-height: 14px;
      width: 30px;
      height: 100%;
      position: absolute;
      z-index: 2;
      top: calc(50% - 6px);
      left: 12px;
    }
    input {
      padding-left: 51px;
    }
  }
}

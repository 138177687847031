.embed-video {
  width: 100%;
  position: relative;
  box-shadow: 0 6px 25px rgba(0, 0, 0, 0.15);
}
.embed-video .thumbnail {
  width: 100%;
}
.embed-video .overlay svg.play-handler {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: calc(100% / 3);
  height: 100%;
  left: calc(100% / 3);
  cursor: pointer;
  color: white;
  transition: color 0.1s linear;
}
.embed-video .overlay svg.play-handler:hover {
  color: #ea5353;
}

@import "../../styles/responsive";
@import "../../styles/ant-theme";

body {
  font-family: 'Bw Modelica';
  font-weight: 400;
  //font-variant: initial;
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
}

.ant-layout {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  background-color: white;
  .ant-layout-content {
    border-top: none;
    padding: 50px 40px 80px;

    .media-phone({padding: 20px 15px 80px;});
    .media-tablet({padding: 40px 35px 80px;});
    .media-desktop({border-top: 1px solid @controls-border;});
    &.registration {
      background-image: linear-gradient(
          113.66deg,
          rgba(35, 22, 177, 0.4) 4.51%,
          rgba(196, 61, 109, 0.4) 88.4%,
          rgba(244, 194, 16, 0.4) 113.46%
        ),
        url("../../styles/images/primary-bg.png");
      background-repeat: no-repeat;
      background-size: 100%;
      background-position-y: 50%;

      .media-tablet({background-size: cover; background-position-x: 50%;});
      .media-phone({background-size: cover;});
    }
    &.login {
      background-image: url("../../styles/images/login-bg.png");
      background-size: cover;
      background-position-y: 80%;

      .media-tablet({background-position-x: 70%;});
      .media-phone({background-position-x: 50%;});
    }
  }
  .ant-layout-footer {
    min-height: 180px;
    background-color: #49354e;
    display: flex;
    justify-content: center;
    .ant-row-flex {
      width: 100%;
    }
  }
}

@primary-color: #837ae9;@text-color: #3d3d46;@text-gray: #a2a7b0;@heading-color: @text-color;@base-disabled-color: #f0f0f0;@base-disabled-text-color: #696b6d;@disabled-bg: @base-disabled-color;@gray: #cdd1d9;@font-size-lg: 16px;@border-color-base: @text-gray;@controls-border: @gray;@border-color-split: @controls-border;@card-radius: 6px;@card-shadow: 0px 10px 20px rgba(65, 39, 70, 0.1);@card-padding-base: 30px;@btn-height-base: 30px;@btn-padding-base: 0 10px;@btn-primary-bg: #ea5353;@btn-primary-bg-hover: #c74545;@btn-primary-bg-active: #983131;@btn-disable-border: @base-disabled-color;@btn-padding-lg: 0 32px;@btn-height-lg: 42px;@btn-default-color: @border-color-base;@input-border-color: @controls-border;@select-border-color: @controls-border;@input-height-lg: 42px;@input-disabled-bg: #fafafa;@page-header-padding-horizontal: 0;@zindex-picker: 1000001;@table-header-bg: #f6f7fb;@table-row-hover-bg: #f6f7fb;@badge-dot-size: 10px;@status-padding-top-bottom: 5px;@status-padding-left-right: 10px;@checkbox-padding-top: 8px;@popup-menu-top-bottom: 10px;@popup-menu-left-right: 21px;@pending-color: #a2a7b0;@radio-wrapper-border-color: #a8a2f0;@switch-checked-bg: #69E283;@switch-unchecked-bg: @gray;
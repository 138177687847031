.ant-table-body {
  overflow-x: auto !important;
  @media all and (max-width: 1080px) {
    overflow-x: scroll !important;
  }
}

.ant-table-thead > tr > th,
.ant-table-selection-column {
  border-bottom-color: #fff !important;
}
.ant-table-thead > tr > th {
  font-weight: bold;
}
.ant-table {
  .media-phone({overflow-x: scroll;});
}
.ant-table-wrapper {
  &.table-compact {
    .ant-table {
      font-size: 12px !important;

      td {
        padding: 16px 10px;
      }
    }
  }
  &.only-single-selection {
    .ant-table-selection {
      display: none;
    }
  }
}
.ant-table-column-title {
  font-weight: bold;
}
.table-expand-icon {
  transition: transform 0.3s ease-out;
  &-opened {
    transform: rotate(180deg);
  }
}

tr.ant-table-expanded-row,
tr.ant-table-expanded-row:hover,
tr.ant-row-parent-expanded {
  background: #f6f7fb !important;
}

tr.ant-row-parent-expanded {
  .ant-table-row-expand-icon-cell {
    border-bottom: none;
  }
}

.ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
  background: @table-row-hover-bg !important;
}

.ant-table-footer {
  border-top: 1px solid #fff !important;
  display: flex;
  background: transparent !important;
  padding-top: 50px !important;
  padding-right: 0 !important;

  &:before {
    background: #fff;
  }
}

[data-sc-index] {
  margin-top: 20px;
  position: relative;
  border-bottom: 1px solid #cdd1d9;

  &:before {
    content: attr(data-sc-index);
    width: 35px;
    position: absolute;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    left: -35px;
    color: #3d3d46;
  }

  &:last-of-type {
    border-bottom: none;
  }
}

.ant-table-tbody > tr > td:nth-child(2),
.ant-table-thead > tr > th:nth-child(2) {
  padding-left: 0 !important;
}
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 16px 0 16px 16px !important;
}
.ant-table-thead > tr > th:last-of-type,
.ant-table-tbody > tr:not(.ant-table-expanded-row) > td:last-of-type {
  padding-right: 16px !important;
}
.ant-table-expanded-row > td:last-of-type {
  padding-right: 0 !important;
}
.ant-table-thead > tr > th:nth-child(1),
.ant-table-tbody > tr > td:nth-child(1) {
  padding-right: 16px !important;
}

@import "../../styles/ant-theme";
@import "../../styles/responsive";

.ant-layout .ant-layout-content.faq {
  padding-top: 0;
  .faq-img {
    background-image: url("../../styles/images/faq-header.jpg");
    background-position-x: center;
    height: 445px;
    position: relative;
    width: 100vw;
    margin-left: calc(((100vw - 100%) / 2) * -1);
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: -100px;
    .media-phone({background-position-x: 70%;});
  }

  .ant-page-header {
    background-color: transparent;
    .media-tablet({padding: 0px;}) !important;
  }
  .ant-page-header-title-view-title {
    color: white;
  }
  .ant-page-header .ant-page-header-title-view-title {
    .media-phone({font-size: 32px;});
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 25px 16px 25px 40px;
    font-size: 16px;
    color: black;
  }
  .ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow
    svg {
    fill: none;
  }
  .vh-section,
  .shipper-section,
  .truck_owner-section,
  .agent-section {
    padding: 50px 0px 25px 0px;
    border-bottom: 1px solid #cdd1d9;
    .media-phone({padding: 65px 0px 25px 0px;});
  }
  .ant-collapse-borderless > .ant-collapse-item {
    border-bottom: 1px solid#CDD1D9;
  }
  .ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow {
    left: 5px;
    font-size: 20px;
    color: #a2a7b0;
  }

  .buttons-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    .media-phone(
      {flex-direction: row; overflow-x: scroll; width: 100vw; background: #fff;}
    );
  }
  .content-wrapper {
    display: flex;
  }
  .affix-wrapper {
    padding-left: 60px;
    padding-top: 30px;

    .ant-affix {
      padding-top: 20px;
      .media-phone({background: #fff;});
    }
  }
  .nav-link {
    border: none;
    color: #837ae9;
    margin-bottom: 10px;
  }
  .button {
    width: 140px;
    border: none;
    color: #837ae9;
    padding: 5px 15px 5px 15px;
    margin-bottom: 10px;
    .media-phone({height: auto; width: auto; margin-right: 5px; padding: 5px;});
  }

  .button:hover,
  .active {
    background-color: #837ae9;
    color: white;
    font-weight: bold;
    border-color: transparent;
    border-radius: 3px;
  }

  .faq-content {
    line-height: 1.5rem;
    padding-left: 30px;
  }
  .footer-section {
    margin-bottom: 50px;
  }
  @media screen and (min-width: 320px) and(max-width:374px) {
    .button {
      width: 70px;
    }
    .vh-section,
    .shipper-section,
    .truck_owner-section,
    .agent-section {
      padding: 75px 0px 25px 0px;
    }
  }
}

@primary-color: #837ae9;@text-color: #3d3d46;@text-gray: #a2a7b0;@heading-color: @text-color;@base-disabled-color: #f0f0f0;@base-disabled-text-color: #696b6d;@disabled-bg: @base-disabled-color;@gray: #cdd1d9;@font-size-lg: 16px;@border-color-base: @text-gray;@controls-border: @gray;@border-color-split: @controls-border;@card-radius: 6px;@card-shadow: 0px 10px 20px rgba(65, 39, 70, 0.1);@card-padding-base: 30px;@btn-height-base: 30px;@btn-padding-base: 0 10px;@btn-primary-bg: #ea5353;@btn-primary-bg-hover: #c74545;@btn-primary-bg-active: #983131;@btn-disable-border: @base-disabled-color;@btn-padding-lg: 0 32px;@btn-height-lg: 42px;@btn-default-color: @border-color-base;@input-border-color: @controls-border;@select-border-color: @controls-border;@input-height-lg: 42px;@input-disabled-bg: #fafafa;@page-header-padding-horizontal: 0;@zindex-picker: 1000001;@table-header-bg: #f6f7fb;@table-row-hover-bg: #f6f7fb;@badge-dot-size: 10px;@status-padding-top-bottom: 5px;@status-padding-left-right: 10px;@checkbox-padding-top: 8px;@popup-menu-top-bottom: 10px;@popup-menu-left-right: 21px;@pending-color: #a2a7b0;@radio-wrapper-border-color: #a8a2f0;@switch-checked-bg: #69E283;@switch-unchecked-bg: @gray;
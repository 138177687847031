@screen-phone: 767px;
@screen-tablet: 922px;
@screen-desktop: 1200px;

.cb-grid-lines {
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  pointer-events: none;
  background: rgba(99, 99, 99, 0.07);
  z-index: 16777271;
  margin: 0;
}

.grid-overlay-container {
  pointer-events: none;
  box-sizing: border-box;
  height: 100%;
  margin: 0 auto;
  position: relative;
  //background: rgba(99, 99, 99, 0.15);
  display: flex;
  flex-direction: row;
}

.grid-overlay-horizontal {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: fixed;
  pointer-events: none;
  z-index: 16777271;
  margin: 0;
}

.grid-overlay-col {
  height: 100%;
  pointer-events: none;
  opacity: 0.25;
  margin: 0 8px;
  display: inline-block;
}

.grid-overlay-row {
  pointer-events: none;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.cb-grid-lines {
  width: 100%;
}
.grid-overlay-container {
  max-width: 1280px;
  padding: 0px 40px;
  left: 0px;
}
.grid-overlay-col {
  width: 4.166666666666667%;
  margin: 0 5px;
  background: rgba(155, 0, 65, 0.2);
}
.grid-overlay-col:first-child {
  margin-left: 0px;
}
.grid-overlay-col:last-child {
  margin-right: 0px;
}
.grid-overlay-horizontal {
  background-image: linear-gradient(
    to top,
    rgba(155, 0, 65, 0.2) 1px,
    transparent 1px
  );
  background-size: 100% 16px;
  background-repeat-y: repeat;
  background-position-y: 0px;
}
.cb-grid-lines {
  width: 100%;
}
.grid-overlay-container {
  max-width: 1280px;
  padding: 0px 40px;
  left: 0px;
}
.grid-overlay-col {
  width: 4.166666666666667%;
  margin: 0 5px;
  background: rgba(155, 0, 65, 0.2);
}
.grid-overlay-col:first-child {
  margin-left: 0px;
}
.grid-overlay-col:last-child {
  margin-right: 0px;
}
.grid-overlay-horizontal {
  background-image: linear-gradient(
    to top,
    rgba(155, 0, 65, 0.2) 1px,
    transparent 1px
  );
  background-size: 100% 16px;
  background-repeat-y: repeat;
  background-position-y: 0px;
}

@media screen and (max-width: @screen-phone) {
  .grid-overlay-col {
    width: 16.666666666666668%;
    margin: 0 5px;
    background: rgba(155, 0, 65, 0.2);
  }
  .grid-overlay-container {
    padding: 0px 15px;
    left: 0px;
  }
  .grid-overlay-col:first-child {
    margin-left: 0px;
  }
  .grid-overlay-col:nth-child(6) {
    margin-right: 0px;
  }
  .grid-overlay-col:nth-child(n + 7) {
    display: none;
  }
}

@media screen and (min-width: (@screen-phone + 1px)) and (max-width: (@screen-desktop - 1px)) {
  .grid-overlay-col {
    width: 8.333333333333334%;
    margin: 0 5px;
    background: rgba(155, 0, 65, 0.2);
  }
  .grid-overlay-container {
    padding: 0px 35px;
    left: 0px;
  }
  .grid-overlay-col:first-child {
    margin-left: 0px;
  }
  .grid-overlay-col:nth-child(12) {
    margin-right: 0px;
  }
  .grid-overlay-col:nth-child(n + 13) {
    display: none;
  }
}




@primary-color: #837ae9;@text-color: #3d3d46;@text-gray: #a2a7b0;@heading-color: @text-color;@base-disabled-color: #f0f0f0;@base-disabled-text-color: #696b6d;@disabled-bg: @base-disabled-color;@gray: #cdd1d9;@font-size-lg: 16px;@border-color-base: @text-gray;@controls-border: @gray;@border-color-split: @controls-border;@card-radius: 6px;@card-shadow: 0px 10px 20px rgba(65, 39, 70, 0.1);@card-padding-base: 30px;@btn-height-base: 30px;@btn-padding-base: 0 10px;@btn-primary-bg: #ea5353;@btn-primary-bg-hover: #c74545;@btn-primary-bg-active: #983131;@btn-disable-border: @base-disabled-color;@btn-padding-lg: 0 32px;@btn-height-lg: 42px;@btn-default-color: @border-color-base;@input-border-color: @controls-border;@select-border-color: @controls-border;@input-height-lg: 42px;@input-disabled-bg: #fafafa;@page-header-padding-horizontal: 0;@zindex-picker: 1000001;@table-header-bg: #f6f7fb;@table-row-hover-bg: #f6f7fb;@badge-dot-size: 10px;@status-padding-top-bottom: 5px;@status-padding-left-right: 10px;@checkbox-padding-top: 8px;@popup-menu-top-bottom: 10px;@popup-menu-left-right: 21px;@pending-color: #a2a7b0;@radio-wrapper-border-color: #a8a2f0;@switch-checked-bg: #69E283;@switch-unchecked-bg: @gray;